

/*******************************
            Button
*******************************/

.ui.button {
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  outline: none;
  border: none;
  vertical-align: baseline;
  background: #E0E1E2 none;
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Lato', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin: 0em 0.25em 0em 0em;
  padding: 0.78571429em 1.5em 0.78571429em;
  text-transform: none;
  text-shadow: none;
  font-weight: bold;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: 0.28571429rem;
  box-shadow: 0px 0px 0px 1px transparent inset, 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease;
  will-change: '';
  -webkit-tap-highlight-color: transparent;
}

/*******************************
            States
*******************************/

/*--------------
      Hover
---------------*/

.ui.button:hover {
  background-color: #CACBCD;
  background-image: none;
  box-shadow: 0px 0px 0px 1px transparent inset, 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
  color: rgba(0, 0, 0, 0.8);
}

.ui.button:hover .icon {
  opacity: 0.85;
}

/*--------------
      Focus
---------------*/

.ui.button:focus {
  background-color: #CACBCD;
  color: rgba(0, 0, 0, 0.8);
  background-image: '' !important;
  box-shadow: '' !important;
}

.ui.button:focus .icon {
  opacity: 0.85;
}

/*--------------
      Down
---------------*/

.ui.button:active,
.ui.active.button:active {
  background-color: #BABBBC;
  background-image: '';
  color: rgba(0, 0, 0, 0.9);
  box-shadow: 0px 0px 0px 1px transparent inset, none;
}

/*--------------
     Active
---------------*/

.ui.active.button {
  background-color: #C0C1C2;
  background-image: none;
  box-shadow: 0px 0px 0px 1px transparent inset;
  color: rgba(0, 0, 0, 0.95);
}

.ui.active.button:hover {
  background-color: #C0C1C2;
  background-image: none;
  color: rgba(0, 0, 0, 0.95);
}

.ui.active.button:active {
  background-color: #C0C1C2;
  background-image: none;
}

/*--------------
    Loading
---------------*/

/* Specificity hack */

.ui.loading.loading.loading.loading.loading.loading.button {
  position: relative;
  cursor: default;
  text-shadow: none !important;
  color: transparent !important;
  opacity: 1;
  pointer-events: auto;
  transition: all 0s linear, opacity 0.1s ease;
}

.ui.loading.button:before {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -0.64285714em 0em 0em -0.64285714em;
  width: 1.28571429em;
  height: 1.28571429em;
  border-radius: 500rem;
  border: 0.2em solid rgba(0, 0, 0, 0.15);
}

.ui.loading.button:after {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -0.64285714em 0em 0em -0.64285714em;
  width: 1.28571429em;
  height: 1.28571429em;
  -webkit-animation: button-spin 0.6s linear;
  animation: button-spin 0.6s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 500rem;
  border-color: #FFFFFF transparent transparent;
  border-style: solid;
  border-width: 0.2em;
  box-shadow: 0px 0px 0px 1px transparent;
}

.ui.labeled.icon.loading.button .icon {
  background-color: transparent;
  box-shadow: none;
}

@-webkit-keyframes button-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes button-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ui.basic.loading.button:not(.inverted):before {
  border-color: rgba(0, 0, 0, 0.1);
}

.ui.basic.loading.button:not(.inverted):after {
  border-top-color: #767676;
}

/*-------------------
      Disabled
--------------------*/

.ui.buttons .disabled.button,
.ui.disabled.button,
.ui.button:disabled,
.ui.disabled.button:hover,
.ui.disabled.active.button {
  cursor: default;
  opacity: 0.45 !important;
  background-image: none !important;
  box-shadow: none !important;
  pointer-events: none !important;
}

/* Basic Group With Disabled */

.ui.basic.buttons .ui.disabled.button {
  border-color: rgba(34, 36, 38, 0.5);
}

/*******************************
             Types
*******************************/

/*-------------------
       Animated
--------------------*/

.ui.animated.button {
  position: relative;
  overflow: hidden;
  padding-right: 0em !important;
  vertical-align: middle;
  z-index: 1;
}

.ui.animated.button .content {
  will-change: transform, opacity;
}

.ui.animated.button .visible.content {
  position: relative;
  margin-right: 1.5em;
}

.ui.animated.button .hidden.content {
  position: absolute;
  width: 100%;
}

/* Horizontal */

.ui.animated.button .visible.content,
.ui.animated.button .hidden.content {
  transition: right 0.3s ease 0s;
}

.ui.animated.button .visible.content {
  left: auto;
  right: 0%;
}

.ui.animated.button .hidden.content {
  top: 50%;
  left: auto;
  right: -100%;
  margin-top: -0.5em;
}

.ui.animated.button:focus .visible.content,
.ui.animated.button:hover .visible.content {
  left: auto;
  right: 200%;
}

.ui.animated.button:focus .hidden.content,
.ui.animated.button:hover .hidden.content {
  left: auto;
  right: 0%;
}

/* Vertical */

.ui.vertical.animated.button .visible.content,
.ui.vertical.animated.button .hidden.content {
  transition: top 0.3s ease, transform 0.3s ease;
}

.ui.vertical.animated.button .visible.content {
  transform: translateY(0%);
  right: auto;
}

.ui.vertical.animated.button .hidden.content {
  top: -50%;
  left: 0%;
  right: auto;
}

.ui.vertical.animated.button:focus .visible.content,
.ui.vertical.animated.button:hover .visible.content {
  transform: translateY(200%);
  right: auto;
}

.ui.vertical.animated.button:focus .hidden.content,
.ui.vertical.animated.button:hover .hidden.content {
  top: 50%;
  right: auto;
}

/* Fade */

.ui.fade.animated.button .visible.content,
.ui.fade.animated.button .hidden.content {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.ui.fade.animated.button .visible.content {
  left: auto;
  right: auto;
  opacity: 1;
  transform: scale(1);
}

.ui.fade.animated.button .hidden.content {
  opacity: 0;
  left: 0%;
  right: auto;
  transform: scale(1.5);
}

.ui.fade.animated.button:focus .visible.content,
.ui.fade.animated.button:hover .visible.content {
  left: auto;
  right: auto;
  opacity: 0;
  transform: scale(0.75);
}

.ui.fade.animated.button:focus .hidden.content,
.ui.fade.animated.button:hover .hidden.content {
  left: 0%;
  right: auto;
  opacity: 1;
  transform: scale(1);
}

/*-------------------
       Inverted
--------------------*/

.ui.inverted.button {
  box-shadow: 0px 0px 0px 2px #FFFFFF inset !important;
  background: transparent none;
  color: #FFFFFF;
  text-shadow: none !important;
}

/* Group */

.ui.inverted.buttons .button {
  margin: 0px 0px 0px -2px;
}

.ui.inverted.buttons .button:first-child {
  margin-left: 0em;
}

.ui.inverted.vertical.buttons .button {
  margin: 0px 0px -2px 0px;
}

.ui.inverted.vertical.buttons .button:first-child {
  margin-top: 0em;
}

/* States */

/* Hover */

.ui.inverted.button:hover {
  background: #FFFFFF;
  box-shadow: 0px 0px 0px 2px #FFFFFF inset !important;
  color: rgba(0, 0, 0, 0.8);
}

/* Active / Focus */

.ui.inverted.button:focus,
.ui.inverted.button.active {
  background: #FFFFFF;
  box-shadow: 0px 0px 0px 2px #FFFFFF inset !important;
  color: rgba(0, 0, 0, 0.8);
}

/* Active Focus */

.ui.inverted.button.active:focus {
  background: #DCDDDE;
  box-shadow: 0px 0px 0px 2px #DCDDDE inset !important;
  color: rgba(0, 0, 0, 0.8);
}

/*-------------------
    Labeled Button
--------------------*/

.ui.labeled.button:not(.icon) {
  display: inline-flex;
  flex-direction: row;
  background: none !important;
  padding: 0px !important;
  border: none !important;
  box-shadow: none !important;
}

.ui.labeled.button > .button {
  margin: 0px;
}

.ui.labeled.button > .label {
  display: flex;
  align-items: center;
  margin: 0px 0px 0px -1px !important;
  padding: '';
  font-size: 1em;
  border-color: rgba(34, 36, 38, 0.15);
}

/* Tag */

.ui.labeled.button > .tag.label:before {
  width: 1.85em;
  height: 1.85em;
}

/* Right */

.ui.labeled.button:not([class*="left labeled"]) > .button {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.ui.labeled.button:not([class*="left labeled"]) > .label {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

/* Left Side */

.ui[class*="left labeled"].button > .button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.ui[class*="left labeled"].button > .label {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

/*-------------------
       Social
--------------------*/

/* Facebook */

.ui.facebook.button {
  background-color: #3B5998;
  color: #FFFFFF;
  text-shadow: none;
  background-image: none;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.facebook.button:hover {
  background-color: #304d8a;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.facebook.button:active {
  background-color: #2d4373;
  color: #FFFFFF;
  text-shadow: none;
}

/* Twitter */

.ui.twitter.button {
  background-color: #55ACEE;
  color: #FFFFFF;
  text-shadow: none;
  background-image: none;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.twitter.button:hover {
  background-color: #35a2f4;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.twitter.button:active {
  background-color: #2795e9;
  color: #FFFFFF;
  text-shadow: none;
}

/* Google Plus */

.ui.google.plus.button {
  background-color: #DD4B39;
  color: #FFFFFF;
  text-shadow: none;
  background-image: none;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.google.plus.button:hover {
  background-color: #e0321c;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.google.plus.button:active {
  background-color: #c23321;
  color: #FFFFFF;
  text-shadow: none;
}

/* Linked In */

.ui.linkedin.button {
  background-color: #1F88BE;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.linkedin.button:hover {
  background-color: #147baf;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.linkedin.button:active {
  background-color: #186992;
  color: #FFFFFF;
  text-shadow: none;
}

/* YouTube */

.ui.youtube.button {
  background-color: #FF0000;
  color: #FFFFFF;
  text-shadow: none;
  background-image: none;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.youtube.button:hover {
  background-color: #e60000;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.youtube.button:active {
  background-color: #cc0000;
  color: #FFFFFF;
  text-shadow: none;
}

/* Instagram */

.ui.instagram.button {
  background-color: #49769C;
  color: #FFFFFF;
  text-shadow: none;
  background-image: none;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.instagram.button:hover {
  background-color: #3d698e;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.instagram.button:active {
  background-color: #395c79;
  color: #FFFFFF;
  text-shadow: none;
}

/* Pinterest */

.ui.pinterest.button {
  background-color: #BD081C;
  color: #FFFFFF;
  text-shadow: none;
  background-image: none;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.pinterest.button:hover {
  background-color: #ac0013;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.pinterest.button:active {
  background-color: #8c0615;
  color: #FFFFFF;
  text-shadow: none;
}

/* VK */

.ui.vk.button {
  background-color: #4D7198;
  color: #FFFFFF;
  background-image: none;
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.vk.button:hover {
  background-color: #41648a;
  color: #FFFFFF;
}

.ui.vk.button:active {
  background-color: #3c5876;
  color: #FFFFFF;
}

/*--------------
     Icon
---------------*/

.ui.button > .icon:not(.button) {
  height: 0.85714286em;
  opacity: 0.8;
  margin: 0em 0.42857143em 0em -0.21428571em;
  transition: opacity 0.1s ease;
  vertical-align: '';
  color: '';
}

.ui.button:not(.icon) > .icon:not(.button):not(.dropdown) {
  margin: 0em 0.42857143em 0em -0.21428571em;
}

.ui.button:not(.icon) > .right.icon:not(.button):not(.dropdown) {
  margin: 0em -0.21428571em 0em 0.42857143em;
}

/*******************************
           Variations
*******************************/

/*-------------------
       Floated
--------------------*/

.ui[class*="left floated"].buttons,
.ui[class*="left floated"].button {
  float: left;
  margin-left: 0em;
  margin-right: 0.25em;
}

.ui[class*="right floated"].buttons,
.ui[class*="right floated"].button {
  float: right;
  margin-right: 0em;
  margin-left: 0.25em;
}

/*-------------------
       Compact
--------------------*/

.ui.compact.buttons .button,
.ui.compact.button {
  padding: 0.58928571em 1.125em 0.58928571em;
}

.ui.compact.icon.buttons .button,
.ui.compact.icon.button {
  padding: 0.58928571em 0.58928571em 0.58928571em;
}

.ui.compact.labeled.icon.buttons .button,
.ui.compact.labeled.icon.button {
  padding: 0.58928571em 3.69642857em 0.58928571em;
}

/*-------------------
        Sizes
--------------------*/

.ui.mini.buttons .button,
.ui.mini.buttons .or,
.ui.mini.button {
  font-size: 0.78571429rem;
}

.ui.tiny.buttons .button,
.ui.tiny.buttons .or,
.ui.tiny.button {
  font-size: 0.85714286rem;
}

.ui.small.buttons .button,
.ui.small.buttons .or,
.ui.small.button {
  font-size: 0.92857143rem;
}

.ui.buttons .button,
.ui.buttons .or,
.ui.button {
  font-size: 1rem;
}

.ui.large.buttons .button,
.ui.large.buttons .or,
.ui.large.button {
  font-size: 1.14285714rem;
}

.ui.big.buttons .button,
.ui.big.buttons .or,
.ui.big.button {
  font-size: 1.28571429rem;
}

.ui.huge.buttons .button,
.ui.huge.buttons .or,
.ui.huge.button {
  font-size: 1.42857143rem;
}

.ui.massive.buttons .button,
.ui.massive.buttons .or,
.ui.massive.button {
  font-size: 1.71428571rem;
}

/*--------------
    Icon Only
---------------*/

.ui.icon.buttons .button,
.ui.icon.button {
  padding: 0.78571429em 0.78571429em 0.78571429em;
}

.ui.icon.buttons .button > .icon,
.ui.icon.button > .icon {
  opacity: 0.9;
  margin: 0em !important;
  vertical-align: top;
}

/*-------------------
        Basic
--------------------*/

.ui.basic.buttons .button,
.ui.basic.button {
  background: transparent none !important;
  color: rgba(0, 0, 0, 0.6) !important;
  font-weight: normal;
  border-radius: 0.28571429rem;
  text-transform: none;
  text-shadow: none !important;
  box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
}

.ui.basic.buttons {
  box-shadow: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
}

.ui.basic.buttons .button {
  border-radius: 0em;
}

.ui.basic.buttons .button:hover,
.ui.basic.button:hover {
  background: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.8) !important;
  box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.35) inset, 0px 0px 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.basic.buttons .button:focus,
.ui.basic.button:focus {
  background: #FFFFFF !important;
  color: rgba(0, 0, 0, 0.8) !important;
  box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.35) inset, 0px 0px 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.basic.buttons .button:active,
.ui.basic.button:active {
  background: #F8F8F8 !important;
  color: rgba(0, 0, 0, 0.9) !important;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15) inset, 0px 1px 4px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.basic.buttons .active.button,
.ui.basic.active.button {
  background: rgba(0, 0, 0, 0.05) !important;
  box-shadow: '' !important;
  color: rgba(0, 0, 0, 0.95) !important;
}

.ui.basic.buttons .active.button:hover,
.ui.basic.active.button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

/* Vertical */

.ui.basic.buttons .button:hover {
  box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.35) inset, 0px 0px 0px 0px rgba(34, 36, 38, 0.15) inset inset;
}

.ui.basic.buttons .button:active {
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.15) inset, 0px 1px 4px 0px rgba(34, 36, 38, 0.15) inset inset;
}

.ui.basic.buttons .active.button {
  box-shadow: '' !important;
}

/* Standard Basic Inverted */

.ui.basic.inverted.buttons .button,
.ui.basic.inverted.button {
  background-color: transparent !important;
  color: #F9FAFB !important;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
}

.ui.basic.inverted.buttons .button:hover,
.ui.basic.inverted.button:hover {
  color: #FFFFFF !important;
  box-shadow: 0px 0px 0px 2px #ffffff inset !important;
}

.ui.basic.inverted.buttons .button:focus,
.ui.basic.inverted.button:focus {
  color: #FFFFFF !important;
  box-shadow: 0px 0px 0px 2px #ffffff inset !important;
}

.ui.basic.inverted.buttons .button:active,
.ui.basic.inverted.button:active {
  background-color: rgba(255, 255, 255, 0.08) !important;
  color: #FFFFFF !important;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.9) inset !important;
}

.ui.basic.inverted.buttons .active.button,
.ui.basic.inverted.active.button {
  background-color: rgba(255, 255, 255, 0.08);
  color: #FFFFFF;
  text-shadow: none;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.7) inset;
}

.ui.basic.inverted.buttons .active.button:hover,
.ui.basic.inverted.active.button:hover {
  background-color: rgba(255, 255, 255, 0.15);
  box-shadow: 0px 0px 0px 2px #ffffff inset !important;
}

/* Basic Group */

.ui.basic.buttons .button {
  border-left: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: none;
}

.ui.basic.vertical.buttons .button {
  border-left: none;
}

.ui.basic.vertical.buttons .button {
  border-left-width: 0px;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
}

.ui.basic.vertical.buttons .button:first-child {
  border-top-width: 0px;
}

/*--------------
  Labeled Icon
---------------*/

.ui.labeled.icon.buttons .button,
.ui.labeled.icon.button {
  position: relative;
  padding-left: 4.07142857em !important;
  padding-right: 1.5em !important;
}

/* Left Labeled */

.ui.labeled.icon.buttons > .button > .icon,
.ui.labeled.icon.button > .icon {
  position: absolute;
  height: 100%;
  line-height: 1;
  border-radius: 0px;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  text-align: center;
  margin: 0em;
  width: 2.57142857em;
  background-color: rgba(0, 0, 0, 0.05);
  color: '';
  box-shadow: -1px 0px 0px 0px transparent inset;
}

/* Left Labeled */

.ui.labeled.icon.buttons > .button > .icon,
.ui.labeled.icon.button > .icon {
  top: 0em;
  left: 0em;
}

/* Right Labeled */

.ui[class*="right labeled"].icon.button {
  padding-right: 4.07142857em !important;
  padding-left: 1.5em !important;
}

.ui[class*="right labeled"].icon.button > .icon {
  left: auto;
  right: 0em;
  border-radius: 0px;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  box-shadow: 1px 0px 0px 0px transparent inset;
}

.ui.labeled.icon.buttons > .button > .icon:before,
.ui.labeled.icon.button > .icon:before,
.ui.labeled.icon.buttons > .button > .icon:after,
.ui.labeled.icon.button > .icon:after {
  display: block;
  position: absolute;
  width: 100%;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
}

/* Loading */

.ui.labeled.icon.button > .icon.loading {
  -webkit-animation: none;
  animation: none;
}

.ui.labeled.icon.button > .icon.loading:before {
  -webkit-animation: labeled-button-icon-loading 2s linear infinite;
  animation: labeled-button-icon-loading 2s linear infinite;
}

@-webkit-keyframes labeled-button-icon-loading {
  from {
    transform: translateY(-50%) rotate(0deg);
  }

  to {
    transform: translateY(-50%) rotate(360deg);
  }
}

@keyframes labeled-button-icon-loading {
  from {
    transform: translateY(-50%) rotate(0deg);
  }

  to {
    transform: translateY(-50%) rotate(360deg);
  }
}

.ui.labeled.icon.buttons .button > .icon {
  border-radius: 0em;
}

.ui.labeled.icon.buttons .button:first-child > .icon {
  border-top-left-radius: 0.28571429rem;
  border-bottom-left-radius: 0.28571429rem;
}

.ui.labeled.icon.buttons .button:last-child > .icon {
  border-top-right-radius: 0.28571429rem;
  border-bottom-right-radius: 0.28571429rem;
}

.ui.vertical.labeled.icon.buttons .button:first-child > .icon {
  border-radius: 0em;
  border-top-left-radius: 0.28571429rem;
}

.ui.vertical.labeled.icon.buttons .button:last-child > .icon {
  border-radius: 0em;
  border-bottom-left-radius: 0.28571429rem;
}

/* Fluid Labeled */

.ui.fluid[class*="left labeled"].icon.button,
.ui.fluid[class*="right labeled"].icon.button {
  padding-left: 1.5em !important;
  padding-right: 1.5em !important;
}

/*--------------
     Toggle
---------------*/

/* Toggle (Modifies active state to give affordances) */

.ui.toggle.buttons .active.button,
.ui.buttons .button.toggle.active,
.ui.button.toggle.active {
  background-color: #21BA45 !important;
  box-shadow: none !important;
  text-shadow: none;
  color: #FFFFFF !important;
}

.ui.button.toggle.active:hover {
  background-color: #16ab39 !important;
  text-shadow: none;
  color: #FFFFFF !important;
}

/*--------------
    Circular
---------------*/

.ui.circular.button {
  border-radius: 10em;
}

.ui.circular.button > .icon {
  width: 1em;
  vertical-align: baseline;
}

/*-------------------
      Or Buttons
--------------------*/

.ui.buttons .or {
  position: relative;
  width: 0.3em;
  height: 2.57142857em;
  z-index: 3;
}

.ui.buttons .or:before {
  position: absolute;
  text-align: center;
  border-radius: 500rem;
  content: 'or';
  top: 50%;
  left: 50%;
  background-color: #FFFFFF;
  text-shadow: none;
  margin-top: -0.89285714em;
  margin-left: -0.89285714em;
  width: 1.78571429em;
  height: 1.78571429em;
  line-height: 1.78571429em;
  color: rgba(0, 0, 0, 0.4);
  font-style: normal;
  font-weight: bold;
  box-shadow: 0px 0px 0px 1px transparent inset;
}

.ui.buttons .or[data-text]:before {
  content: attr(data-text);
}

/* Fluid Or */

.ui.fluid.buttons .or {
  width: 0em !important;
}

.ui.fluid.buttons .or:after {
  display: none;
}

/*-------------------
       Attached
--------------------*/

/* Singular */

.ui.attached.button {
  position: relative;
  display: block;
  margin: 0em;
  border-radius: 0em;
  box-shadow: 0px 0px 0px 1px rgba(34, 36, 38, 0.15) !important;
}

/* Top / Bottom */

.ui.attached.top.button {
  border-radius: 0.28571429rem 0.28571429rem 0em 0em;
}

.ui.attached.bottom.button {
  border-radius: 0em 0em 0.28571429rem 0.28571429rem;
}

/* Left / Right */

.ui.left.attached.button {
  display: inline-block;
  border-left: none;
  text-align: right;
  padding-right: 0.75em;
  border-radius: 0.28571429rem 0em 0em 0.28571429rem;
}

.ui.right.attached.button {
  display: inline-block;
  text-align: left;
  padding-left: 0.75em;
  border-radius: 0em 0.28571429rem 0.28571429rem 0em;
}

/* Plural */

.ui.attached.buttons {
  position: relative;
  display: flex;
  border-radius: 0em;
  width: auto !important;
  z-index: 2;
  margin-left: -1px;
  margin-right: -1px;
}

.ui.attached.buttons .button {
  margin: 0em;
}

.ui.attached.buttons .button:first-child {
  border-radius: 0em;
}

.ui.attached.buttons .button:last-child {
  border-radius: 0em;
}

/* Top / Bottom */

.ui[class*="top attached"].buttons {
  margin-bottom: -1px;
  border-radius: 0.28571429rem 0.28571429rem 0em 0em;
}

.ui[class*="top attached"].buttons .button:first-child {
  border-radius: 0.28571429rem 0em 0em 0em;
}

.ui[class*="top attached"].buttons .button:last-child {
  border-radius: 0em 0.28571429rem 0em 0em;
}

.ui[class*="bottom attached"].buttons {
  margin-top: -1px;
  border-radius: 0em 0em 0.28571429rem 0.28571429rem;
}

.ui[class*="bottom attached"].buttons .button:first-child {
  border-radius: 0em 0em 0em 0.28571429rem;
}

.ui[class*="bottom attached"].buttons .button:last-child {
  border-radius: 0em 0em 0.28571429rem 0em;
}

/* Left / Right */

.ui[class*="left attached"].buttons {
  display: inline-flex;
  margin-right: 0em;
  margin-left: -1px;
  border-radius: 0em 0.28571429rem 0.28571429rem 0em;
}

.ui[class*="left attached"].buttons .button:first-child {
  margin-left: -1px;
  border-radius: 0em 0.28571429rem 0em 0em;
}

.ui[class*="left attached"].buttons .button:last-child {
  margin-left: -1px;
  border-radius: 0em 0em 0.28571429rem 0em;
}

.ui[class*="right attached"].buttons {
  display: inline-flex;
  margin-left: 0em;
  margin-right: -1px;
  border-radius: 0.28571429rem 0em 0em 0.28571429rem;
}

.ui[class*="right attached"].buttons .button:first-child {
  margin-left: -1px;
  border-radius: 0.28571429rem 0em 0em 0em;
}

.ui[class*="right attached"].buttons .button:last-child {
  margin-left: -1px;
  border-radius: 0em 0em 0em 0.28571429rem;
}

/*-------------------
        Fluid
--------------------*/

.ui.fluid.buttons,
.ui.fluid.button {
  width: 100%;
}

.ui.fluid.button {
  display: block;
}

.ui.two.buttons {
  width: 100%;
}

.ui.two.buttons > .button {
  width: 50%;
}

.ui.three.buttons {
  width: 100%;
}

.ui.three.buttons > .button {
  width: 33.333%;
}

.ui.four.buttons {
  width: 100%;
}

.ui.four.buttons > .button {
  width: 25%;
}

.ui.five.buttons {
  width: 100%;
}

.ui.five.buttons > .button {
  width: 20%;
}

.ui.six.buttons {
  width: 100%;
}

.ui.six.buttons > .button {
  width: 16.666%;
}

.ui.seven.buttons {
  width: 100%;
}

.ui.seven.buttons > .button {
  width: 14.285%;
}

.ui.eight.buttons {
  width: 100%;
}

.ui.eight.buttons > .button {
  width: 12.5%;
}

.ui.nine.buttons {
  width: 100%;
}

.ui.nine.buttons > .button {
  width: 11.11%;
}

.ui.ten.buttons {
  width: 100%;
}

.ui.ten.buttons > .button {
  width: 10%;
}

.ui.eleven.buttons {
  width: 100%;
}

.ui.eleven.buttons > .button {
  width: 9.09%;
}

.ui.twelve.buttons {
  width: 100%;
}

.ui.twelve.buttons > .button {
  width: 8.3333%;
}

/* Fluid Vertical Buttons */

.ui.fluid.vertical.buttons,
.ui.fluid.vertical.buttons > .button {
  display: flex;
  width: auto;
}

.ui.two.vertical.buttons > .button {
  height: 50%;
}

.ui.three.vertical.buttons > .button {
  height: 33.333%;
}

.ui.four.vertical.buttons > .button {
  height: 25%;
}

.ui.five.vertical.buttons > .button {
  height: 20%;
}

.ui.six.vertical.buttons > .button {
  height: 16.666%;
}

.ui.seven.vertical.buttons > .button {
  height: 14.285%;
}

.ui.eight.vertical.buttons > .button {
  height: 12.5%;
}

.ui.nine.vertical.buttons > .button {
  height: 11.11%;
}

.ui.ten.vertical.buttons > .button {
  height: 10%;
}

.ui.eleven.vertical.buttons > .button {
  height: 9.09%;
}

.ui.twelve.vertical.buttons > .button {
  height: 8.3333%;
}

/*-------------------
       Colors
--------------------*/

/*--- Black ---*/

.ui.black.buttons .button,
.ui.black.button {
  background-color: #1B1C1D;
  color: #FFFFFF;
  text-shadow: none;
  background-image: none;
}

.ui.black.button {
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.black.buttons .button:hover,
.ui.black.button:hover {
  background-color: #27292a;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.black.buttons .button:focus,
.ui.black.button:focus {
  background-color: #2f3032;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.black.buttons .button:active,
.ui.black.button:active {
  background-color: #343637;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.black.buttons .active.button,
.ui.black.buttons .active.button:active,
.ui.black.active.button,
.ui.black.button .active.button:active {
  background-color: #0f0f10;
  color: #FFFFFF;
  text-shadow: none;
}

/* Basic */

.ui.basic.black.buttons .button,
.ui.basic.black.button {
  box-shadow: 0px 0px 0px 1px #1B1C1D inset !important;
  color: #1B1C1D !important;
}

.ui.basic.black.buttons .button:hover,
.ui.basic.black.button:hover {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #27292a inset !important;
  color: #27292a !important;
}

.ui.basic.black.buttons .button:focus,
.ui.basic.black.button:focus {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #2f3032 inset !important;
  color: #27292a !important;
}

.ui.basic.black.buttons .active.button,
.ui.basic.black.active.button {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #0f0f10 inset !important;
  color: #343637 !important;
}

.ui.basic.black.buttons .button:active,
.ui.basic.black.button:active {
  box-shadow: 0px 0px 0px 1px #343637 inset !important;
  color: #343637 !important;
}

.ui.buttons:not(.vertical) > .basic.black.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.black.buttons .button,
.ui.inverted.black.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px #D4D4D5 inset !important;
  color: #FFFFFF;
}

.ui.inverted.black.buttons .button:hover,
.ui.inverted.black.button:hover,
.ui.inverted.black.buttons .button:focus,
.ui.inverted.black.button:focus,
.ui.inverted.black.buttons .button.active,
.ui.inverted.black.button.active,
.ui.inverted.black.buttons .button:active,
.ui.inverted.black.button:active {
  box-shadow: none !important;
  color: #FFFFFF;
}

.ui.inverted.black.buttons .button:hover,
.ui.inverted.black.button:hover {
  background-color: #000000;
}

.ui.inverted.black.buttons .button:focus,
.ui.inverted.black.button:focus {
  background-color: #000000;
}

.ui.inverted.black.buttons .active.button,
.ui.inverted.black.active.button {
  background-color: #000000;
}

.ui.inverted.black.buttons .button:active,
.ui.inverted.black.button:active {
  background-color: #000000;
}

/* Inverted Basic */

.ui.inverted.black.basic.buttons .button,
.ui.inverted.black.buttons .basic.button,
.ui.inverted.black.basic.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: #FFFFFF !important;
}

.ui.inverted.black.basic.buttons .button:hover,
.ui.inverted.black.buttons .basic.button:hover,
.ui.inverted.black.basic.button:hover {
  box-shadow: 0px 0px 0px 2px #000000 inset !important;
  color: #FFFFFF !important;
}

.ui.inverted.black.basic.buttons .button:focus,
.ui.inverted.black.basic.buttons .button:focus,
.ui.inverted.black.basic.button:focus {
  box-shadow: 0px 0px 0px 2px #000000 inset !important;
  color: #545454 !important;
}

.ui.inverted.black.basic.buttons .active.button,
.ui.inverted.black.buttons .basic.active.button,
.ui.inverted.black.basic.active.button {
  box-shadow: 0px 0px 0px 2px #000000 inset !important;
  color: #FFFFFF !important;
}

.ui.inverted.black.basic.buttons .button:active,
.ui.inverted.black.buttons .basic.button:active,
.ui.inverted.black.basic.button:active {
  box-shadow: 0px 0px 0px 2px #000000 inset !important;
  color: #FFFFFF !important;
}

/*--- Grey ---*/

.ui.grey.buttons .button,
.ui.grey.button {
  background-color: #767676;
  color: #FFFFFF;
  text-shadow: none;
  background-image: none;
}

.ui.grey.button {
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.grey.buttons .button:hover,
.ui.grey.button:hover {
  background-color: #838383;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.grey.buttons .button:focus,
.ui.grey.button:focus {
  background-color: #8a8a8a;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.grey.buttons .button:active,
.ui.grey.button:active {
  background-color: #909090;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.grey.buttons .active.button,
.ui.grey.buttons .active.button:active,
.ui.grey.active.button,
.ui.grey.button .active.button:active {
  background-color: #696969;
  color: #FFFFFF;
  text-shadow: none;
}

/* Basic */

.ui.basic.grey.buttons .button,
.ui.basic.grey.button {
  box-shadow: 0px 0px 0px 1px #767676 inset !important;
  color: #767676 !important;
}

.ui.basic.grey.buttons .button:hover,
.ui.basic.grey.button:hover {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #838383 inset !important;
  color: #838383 !important;
}

.ui.basic.grey.buttons .button:focus,
.ui.basic.grey.button:focus {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #8a8a8a inset !important;
  color: #838383 !important;
}

.ui.basic.grey.buttons .active.button,
.ui.basic.grey.active.button {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #696969 inset !important;
  color: #909090 !important;
}

.ui.basic.grey.buttons .button:active,
.ui.basic.grey.button:active {
  box-shadow: 0px 0px 0px 1px #909090 inset !important;
  color: #909090 !important;
}

.ui.buttons:not(.vertical) > .basic.grey.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.grey.buttons .button,
.ui.inverted.grey.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px #D4D4D5 inset !important;
  color: #FFFFFF;
}

.ui.inverted.grey.buttons .button:hover,
.ui.inverted.grey.button:hover,
.ui.inverted.grey.buttons .button:focus,
.ui.inverted.grey.button:focus,
.ui.inverted.grey.buttons .button.active,
.ui.inverted.grey.button.active,
.ui.inverted.grey.buttons .button:active,
.ui.inverted.grey.button:active {
  box-shadow: none !important;
  color: rgba(0, 0, 0, 0.6);
}

.ui.inverted.grey.buttons .button:hover,
.ui.inverted.grey.button:hover {
  background-color: #cfd0d2;
}

.ui.inverted.grey.buttons .button:focus,
.ui.inverted.grey.button:focus {
  background-color: #c7c9cb;
}

.ui.inverted.grey.buttons .active.button,
.ui.inverted.grey.active.button {
  background-color: #cfd0d2;
}

.ui.inverted.grey.buttons .button:active,
.ui.inverted.grey.button:active {
  background-color: #c2c4c5;
}

/* Inverted Basic */

.ui.inverted.grey.basic.buttons .button,
.ui.inverted.grey.buttons .basic.button,
.ui.inverted.grey.basic.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: #FFFFFF !important;
}

.ui.inverted.grey.basic.buttons .button:hover,
.ui.inverted.grey.buttons .basic.button:hover,
.ui.inverted.grey.basic.button:hover {
  box-shadow: 0px 0px 0px 2px #cfd0d2 inset !important;
  color: #FFFFFF !important;
}

.ui.inverted.grey.basic.buttons .button:focus,
.ui.inverted.grey.basic.buttons .button:focus,
.ui.inverted.grey.basic.button:focus {
  box-shadow: 0px 0px 0px 2px #c7c9cb inset !important;
  color: #DCDDDE !important;
}

.ui.inverted.grey.basic.buttons .active.button,
.ui.inverted.grey.buttons .basic.active.button,
.ui.inverted.grey.basic.active.button {
  box-shadow: 0px 0px 0px 2px #cfd0d2 inset !important;
  color: #FFFFFF !important;
}

.ui.inverted.grey.basic.buttons .button:active,
.ui.inverted.grey.buttons .basic.button:active,
.ui.inverted.grey.basic.button:active {
  box-shadow: 0px 0px 0px 2px #c2c4c5 inset !important;
  color: #FFFFFF !important;
}

/*--- Brown ---*/

.ui.brown.buttons .button,
.ui.brown.button {
  background-color: #A5673F;
  color: #FFFFFF;
  text-shadow: none;
  background-image: none;
}

.ui.brown.button {
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.brown.buttons .button:hover,
.ui.brown.button:hover {
  background-color: #975b33;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.brown.buttons .button:focus,
.ui.brown.button:focus {
  background-color: #90532b;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.brown.buttons .button:active,
.ui.brown.button:active {
  background-color: #805031;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.brown.buttons .active.button,
.ui.brown.buttons .active.button:active,
.ui.brown.active.button,
.ui.brown.button .active.button:active {
  background-color: #995a31;
  color: #FFFFFF;
  text-shadow: none;
}

/* Basic */

.ui.basic.brown.buttons .button,
.ui.basic.brown.button {
  box-shadow: 0px 0px 0px 1px #A5673F inset !important;
  color: #A5673F !important;
}

.ui.basic.brown.buttons .button:hover,
.ui.basic.brown.button:hover {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #975b33 inset !important;
  color: #975b33 !important;
}

.ui.basic.brown.buttons .button:focus,
.ui.basic.brown.button:focus {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #90532b inset !important;
  color: #975b33 !important;
}

.ui.basic.brown.buttons .active.button,
.ui.basic.brown.active.button {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #995a31 inset !important;
  color: #805031 !important;
}

.ui.basic.brown.buttons .button:active,
.ui.basic.brown.button:active {
  box-shadow: 0px 0px 0px 1px #805031 inset !important;
  color: #805031 !important;
}

.ui.buttons:not(.vertical) > .basic.brown.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.brown.buttons .button,
.ui.inverted.brown.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px #D67C1C inset !important;
  color: #D67C1C;
}

.ui.inverted.brown.buttons .button:hover,
.ui.inverted.brown.button:hover,
.ui.inverted.brown.buttons .button:focus,
.ui.inverted.brown.button:focus,
.ui.inverted.brown.buttons .button.active,
.ui.inverted.brown.button.active,
.ui.inverted.brown.buttons .button:active,
.ui.inverted.brown.button:active {
  box-shadow: none !important;
  color: #FFFFFF;
}

.ui.inverted.brown.buttons .button:hover,
.ui.inverted.brown.button:hover {
  background-color: #c86f11;
}

.ui.inverted.brown.buttons .button:focus,
.ui.inverted.brown.button:focus {
  background-color: #c16808;
}

.ui.inverted.brown.buttons .active.button,
.ui.inverted.brown.active.button {
  background-color: #cc6f0d;
}

.ui.inverted.brown.buttons .button:active,
.ui.inverted.brown.button:active {
  background-color: #a96216;
}

/* Inverted Basic */

.ui.inverted.brown.basic.buttons .button,
.ui.inverted.brown.buttons .basic.button,
.ui.inverted.brown.basic.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: #FFFFFF !important;
}

.ui.inverted.brown.basic.buttons .button:hover,
.ui.inverted.brown.buttons .basic.button:hover,
.ui.inverted.brown.basic.button:hover {
  box-shadow: 0px 0px 0px 2px #c86f11 inset !important;
  color: #D67C1C !important;
}

.ui.inverted.brown.basic.buttons .button:focus,
.ui.inverted.brown.basic.buttons .button:focus,
.ui.inverted.brown.basic.button:focus {
  box-shadow: 0px 0px 0px 2px #c16808 inset !important;
  color: #D67C1C !important;
}

.ui.inverted.brown.basic.buttons .active.button,
.ui.inverted.brown.buttons .basic.active.button,
.ui.inverted.brown.basic.active.button {
  box-shadow: 0px 0px 0px 2px #cc6f0d inset !important;
  color: #D67C1C !important;
}

.ui.inverted.brown.basic.buttons .button:active,
.ui.inverted.brown.buttons .basic.button:active,
.ui.inverted.brown.basic.button:active {
  box-shadow: 0px 0px 0px 2px #a96216 inset !important;
  color: #D67C1C !important;
}

/*--- Blue ---*/

.ui.blue.buttons .button,
.ui.blue.button {
  background-color: #2185D0;
  color: #FFFFFF;
  text-shadow: none;
  background-image: none;
}

.ui.blue.button {
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.blue.buttons .button:hover,
.ui.blue.button:hover {
  background-color: #1678c2;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.blue.buttons .button:focus,
.ui.blue.button:focus {
  background-color: #0d71bb;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.blue.buttons .button:active,
.ui.blue.button:active {
  background-color: #1a69a4;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.blue.buttons .active.button,
.ui.blue.buttons .active.button:active,
.ui.blue.active.button,
.ui.blue.button .active.button:active {
  background-color: #1279c6;
  color: #FFFFFF;
  text-shadow: none;
}

/* Basic */

.ui.basic.blue.buttons .button,
.ui.basic.blue.button {
  box-shadow: 0px 0px 0px 1px #2185D0 inset !important;
  color: #2185D0 !important;
}

.ui.basic.blue.buttons .button:hover,
.ui.basic.blue.button:hover {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #1678c2 inset !important;
  color: #1678c2 !important;
}

.ui.basic.blue.buttons .button:focus,
.ui.basic.blue.button:focus {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #0d71bb inset !important;
  color: #1678c2 !important;
}

.ui.basic.blue.buttons .active.button,
.ui.basic.blue.active.button {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #1279c6 inset !important;
  color: #1a69a4 !important;
}

.ui.basic.blue.buttons .button:active,
.ui.basic.blue.button:active {
  box-shadow: 0px 0px 0px 1px #1a69a4 inset !important;
  color: #1a69a4 !important;
}

.ui.buttons:not(.vertical) > .basic.blue.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.blue.buttons .button,
.ui.inverted.blue.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px #54C8FF inset !important;
  color: #54C8FF;
}

.ui.inverted.blue.buttons .button:hover,
.ui.inverted.blue.button:hover,
.ui.inverted.blue.buttons .button:focus,
.ui.inverted.blue.button:focus,
.ui.inverted.blue.buttons .button.active,
.ui.inverted.blue.button.active,
.ui.inverted.blue.buttons .button:active,
.ui.inverted.blue.button:active {
  box-shadow: none !important;
  color: #FFFFFF;
}

.ui.inverted.blue.buttons .button:hover,
.ui.inverted.blue.button:hover {
  background-color: #3ac0ff;
}

.ui.inverted.blue.buttons .button:focus,
.ui.inverted.blue.button:focus {
  background-color: #2bbbff;
}

.ui.inverted.blue.buttons .active.button,
.ui.inverted.blue.active.button {
  background-color: #3ac0ff;
}

.ui.inverted.blue.buttons .button:active,
.ui.inverted.blue.button:active {
  background-color: #21b8ff;
}

/* Inverted Basic */

.ui.inverted.blue.basic.buttons .button,
.ui.inverted.blue.buttons .basic.button,
.ui.inverted.blue.basic.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: #FFFFFF !important;
}

.ui.inverted.blue.basic.buttons .button:hover,
.ui.inverted.blue.buttons .basic.button:hover,
.ui.inverted.blue.basic.button:hover {
  box-shadow: 0px 0px 0px 2px #3ac0ff inset !important;
  color: #54C8FF !important;
}

.ui.inverted.blue.basic.buttons .button:focus,
.ui.inverted.blue.basic.buttons .button:focus,
.ui.inverted.blue.basic.button:focus {
  box-shadow: 0px 0px 0px 2px #2bbbff inset !important;
  color: #54C8FF !important;
}

.ui.inverted.blue.basic.buttons .active.button,
.ui.inverted.blue.buttons .basic.active.button,
.ui.inverted.blue.basic.active.button {
  box-shadow: 0px 0px 0px 2px #3ac0ff inset !important;
  color: #54C8FF !important;
}

.ui.inverted.blue.basic.buttons .button:active,
.ui.inverted.blue.buttons .basic.button:active,
.ui.inverted.blue.basic.button:active {
  box-shadow: 0px 0px 0px 2px #21b8ff inset !important;
  color: #54C8FF !important;
}

/*--- Green ---*/

.ui.green.buttons .button,
.ui.green.button {
  background-color: #21BA45;
  color: #FFFFFF;
  text-shadow: none;
  background-image: none;
}

.ui.green.button {
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.green.buttons .button:hover,
.ui.green.button:hover {
  background-color: #16ab39;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.green.buttons .button:focus,
.ui.green.button:focus {
  background-color: #0ea432;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.green.buttons .button:active,
.ui.green.button:active {
  background-color: #198f35;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.green.buttons .active.button,
.ui.green.buttons .active.button:active,
.ui.green.active.button,
.ui.green.button .active.button:active {
  background-color: #13ae38;
  color: #FFFFFF;
  text-shadow: none;
}

/* Basic */

.ui.basic.green.buttons .button,
.ui.basic.green.button {
  box-shadow: 0px 0px 0px 1px #21BA45 inset !important;
  color: #21BA45 !important;
}

.ui.basic.green.buttons .button:hover,
.ui.basic.green.button:hover {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #16ab39 inset !important;
  color: #16ab39 !important;
}

.ui.basic.green.buttons .button:focus,
.ui.basic.green.button:focus {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #0ea432 inset !important;
  color: #16ab39 !important;
}

.ui.basic.green.buttons .active.button,
.ui.basic.green.active.button {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #13ae38 inset !important;
  color: #198f35 !important;
}

.ui.basic.green.buttons .button:active,
.ui.basic.green.button:active {
  box-shadow: 0px 0px 0px 1px #198f35 inset !important;
  color: #198f35 !important;
}

.ui.buttons:not(.vertical) > .basic.green.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.green.buttons .button,
.ui.inverted.green.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px #2ECC40 inset !important;
  color: #2ECC40;
}

.ui.inverted.green.buttons .button:hover,
.ui.inverted.green.button:hover,
.ui.inverted.green.buttons .button:focus,
.ui.inverted.green.button:focus,
.ui.inverted.green.buttons .button.active,
.ui.inverted.green.button.active,
.ui.inverted.green.buttons .button:active,
.ui.inverted.green.button:active {
  box-shadow: none !important;
  color: #FFFFFF;
}

.ui.inverted.green.buttons .button:hover,
.ui.inverted.green.button:hover {
  background-color: #22be34;
}

.ui.inverted.green.buttons .button:focus,
.ui.inverted.green.button:focus {
  background-color: #19b82b;
}

.ui.inverted.green.buttons .active.button,
.ui.inverted.green.active.button {
  background-color: #1fc231;
}

.ui.inverted.green.buttons .button:active,
.ui.inverted.green.button:active {
  background-color: #25a233;
}

/* Inverted Basic */

.ui.inverted.green.basic.buttons .button,
.ui.inverted.green.buttons .basic.button,
.ui.inverted.green.basic.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: #FFFFFF !important;
}

.ui.inverted.green.basic.buttons .button:hover,
.ui.inverted.green.buttons .basic.button:hover,
.ui.inverted.green.basic.button:hover {
  box-shadow: 0px 0px 0px 2px #22be34 inset !important;
  color: #2ECC40 !important;
}

.ui.inverted.green.basic.buttons .button:focus,
.ui.inverted.green.basic.buttons .button:focus,
.ui.inverted.green.basic.button:focus {
  box-shadow: 0px 0px 0px 2px #19b82b inset !important;
  color: #2ECC40 !important;
}

.ui.inverted.green.basic.buttons .active.button,
.ui.inverted.green.buttons .basic.active.button,
.ui.inverted.green.basic.active.button {
  box-shadow: 0px 0px 0px 2px #1fc231 inset !important;
  color: #2ECC40 !important;
}

.ui.inverted.green.basic.buttons .button:active,
.ui.inverted.green.buttons .basic.button:active,
.ui.inverted.green.basic.button:active {
  box-shadow: 0px 0px 0px 2px #25a233 inset !important;
  color: #2ECC40 !important;
}

/*--- Orange ---*/

.ui.orange.buttons .button,
.ui.orange.button {
  background-color: #F2711C;
  color: #FFFFFF;
  text-shadow: none;
  background-image: none;
}

.ui.orange.button {
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.orange.buttons .button:hover,
.ui.orange.button:hover {
  background-color: #f26202;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.orange.buttons .button:focus,
.ui.orange.button:focus {
  background-color: #e55b00;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.orange.buttons .button:active,
.ui.orange.button:active {
  background-color: #cf590c;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.orange.buttons .active.button,
.ui.orange.buttons .active.button:active,
.ui.orange.active.button,
.ui.orange.button .active.button:active {
  background-color: #f56100;
  color: #FFFFFF;
  text-shadow: none;
}

/* Basic */

.ui.basic.orange.buttons .button,
.ui.basic.orange.button {
  box-shadow: 0px 0px 0px 1px #F2711C inset !important;
  color: #F2711C !important;
}

.ui.basic.orange.buttons .button:hover,
.ui.basic.orange.button:hover {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #f26202 inset !important;
  color: #f26202 !important;
}

.ui.basic.orange.buttons .button:focus,
.ui.basic.orange.button:focus {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #e55b00 inset !important;
  color: #f26202 !important;
}

.ui.basic.orange.buttons .active.button,
.ui.basic.orange.active.button {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #f56100 inset !important;
  color: #cf590c !important;
}

.ui.basic.orange.buttons .button:active,
.ui.basic.orange.button:active {
  box-shadow: 0px 0px 0px 1px #cf590c inset !important;
  color: #cf590c !important;
}

.ui.buttons:not(.vertical) > .basic.orange.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.orange.buttons .button,
.ui.inverted.orange.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px #FF851B inset !important;
  color: #FF851B;
}

.ui.inverted.orange.buttons .button:hover,
.ui.inverted.orange.button:hover,
.ui.inverted.orange.buttons .button:focus,
.ui.inverted.orange.button:focus,
.ui.inverted.orange.buttons .button.active,
.ui.inverted.orange.button.active,
.ui.inverted.orange.buttons .button:active,
.ui.inverted.orange.button:active {
  box-shadow: none !important;
  color: #FFFFFF;
}

.ui.inverted.orange.buttons .button:hover,
.ui.inverted.orange.button:hover {
  background-color: #ff7701;
}

.ui.inverted.orange.buttons .button:focus,
.ui.inverted.orange.button:focus {
  background-color: #f17000;
}

.ui.inverted.orange.buttons .active.button,
.ui.inverted.orange.active.button {
  background-color: #ff7701;
}

.ui.inverted.orange.buttons .button:active,
.ui.inverted.orange.button:active {
  background-color: #e76b00;
}

/* Inverted Basic */

.ui.inverted.orange.basic.buttons .button,
.ui.inverted.orange.buttons .basic.button,
.ui.inverted.orange.basic.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: #FFFFFF !important;
}

.ui.inverted.orange.basic.buttons .button:hover,
.ui.inverted.orange.buttons .basic.button:hover,
.ui.inverted.orange.basic.button:hover {
  box-shadow: 0px 0px 0px 2px #ff7701 inset !important;
  color: #FF851B !important;
}

.ui.inverted.orange.basic.buttons .button:focus,
.ui.inverted.orange.basic.buttons .button:focus,
.ui.inverted.orange.basic.button:focus {
  box-shadow: 0px 0px 0px 2px #f17000 inset !important;
  color: #FF851B !important;
}

.ui.inverted.orange.basic.buttons .active.button,
.ui.inverted.orange.buttons .basic.active.button,
.ui.inverted.orange.basic.active.button {
  box-shadow: 0px 0px 0px 2px #ff7701 inset !important;
  color: #FF851B !important;
}

.ui.inverted.orange.basic.buttons .button:active,
.ui.inverted.orange.buttons .basic.button:active,
.ui.inverted.orange.basic.button:active {
  box-shadow: 0px 0px 0px 2px #e76b00 inset !important;
  color: #FF851B !important;
}

/*--- Pink ---*/

.ui.pink.buttons .button,
.ui.pink.button {
  background-color: #E03997;
  color: #FFFFFF;
  text-shadow: none;
  background-image: none;
}

.ui.pink.button {
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.pink.buttons .button:hover,
.ui.pink.button:hover {
  background-color: #e61a8d;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.pink.buttons .button:focus,
.ui.pink.button:focus {
  background-color: #e10f85;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.pink.buttons .button:active,
.ui.pink.button:active {
  background-color: #c71f7e;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.pink.buttons .active.button,
.ui.pink.buttons .active.button:active,
.ui.pink.active.button,
.ui.pink.button .active.button:active {
  background-color: #ea158d;
  color: #FFFFFF;
  text-shadow: none;
}

/* Basic */

.ui.basic.pink.buttons .button,
.ui.basic.pink.button {
  box-shadow: 0px 0px 0px 1px #E03997 inset !important;
  color: #E03997 !important;
}

.ui.basic.pink.buttons .button:hover,
.ui.basic.pink.button:hover {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #e61a8d inset !important;
  color: #e61a8d !important;
}

.ui.basic.pink.buttons .button:focus,
.ui.basic.pink.button:focus {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #e10f85 inset !important;
  color: #e61a8d !important;
}

.ui.basic.pink.buttons .active.button,
.ui.basic.pink.active.button {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #ea158d inset !important;
  color: #c71f7e !important;
}

.ui.basic.pink.buttons .button:active,
.ui.basic.pink.button:active {
  box-shadow: 0px 0px 0px 1px #c71f7e inset !important;
  color: #c71f7e !important;
}

.ui.buttons:not(.vertical) > .basic.pink.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.pink.buttons .button,
.ui.inverted.pink.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px #FF8EDF inset !important;
  color: #FF8EDF;
}

.ui.inverted.pink.buttons .button:hover,
.ui.inverted.pink.button:hover,
.ui.inverted.pink.buttons .button:focus,
.ui.inverted.pink.button:focus,
.ui.inverted.pink.buttons .button.active,
.ui.inverted.pink.button.active,
.ui.inverted.pink.buttons .button:active,
.ui.inverted.pink.button:active {
  box-shadow: none !important;
  color: #FFFFFF;
}

.ui.inverted.pink.buttons .button:hover,
.ui.inverted.pink.button:hover {
  background-color: #ff74d8;
}

.ui.inverted.pink.buttons .button:focus,
.ui.inverted.pink.button:focus {
  background-color: #ff65d3;
}

.ui.inverted.pink.buttons .active.button,
.ui.inverted.pink.active.button {
  background-color: #ff74d8;
}

.ui.inverted.pink.buttons .button:active,
.ui.inverted.pink.button:active {
  background-color: #ff5bd1;
}

/* Inverted Basic */

.ui.inverted.pink.basic.buttons .button,
.ui.inverted.pink.buttons .basic.button,
.ui.inverted.pink.basic.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: #FFFFFF !important;
}

.ui.inverted.pink.basic.buttons .button:hover,
.ui.inverted.pink.buttons .basic.button:hover,
.ui.inverted.pink.basic.button:hover {
  box-shadow: 0px 0px 0px 2px #ff74d8 inset !important;
  color: #FF8EDF !important;
}

.ui.inverted.pink.basic.buttons .button:focus,
.ui.inverted.pink.basic.buttons .button:focus,
.ui.inverted.pink.basic.button:focus {
  box-shadow: 0px 0px 0px 2px #ff65d3 inset !important;
  color: #FF8EDF !important;
}

.ui.inverted.pink.basic.buttons .active.button,
.ui.inverted.pink.buttons .basic.active.button,
.ui.inverted.pink.basic.active.button {
  box-shadow: 0px 0px 0px 2px #ff74d8 inset !important;
  color: #FF8EDF !important;
}

.ui.inverted.pink.basic.buttons .button:active,
.ui.inverted.pink.buttons .basic.button:active,
.ui.inverted.pink.basic.button:active {
  box-shadow: 0px 0px 0px 2px #ff5bd1 inset !important;
  color: #FF8EDF !important;
}

/*--- Violet ---*/

.ui.violet.buttons .button,
.ui.violet.button {
  background-color: #6435C9;
  color: #FFFFFF;
  text-shadow: none;
  background-image: none;
}

.ui.violet.button {
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.violet.buttons .button:hover,
.ui.violet.button:hover {
  background-color: #5829bb;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.violet.buttons .button:focus,
.ui.violet.button:focus {
  background-color: #4f20b5;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.violet.buttons .button:active,
.ui.violet.button:active {
  background-color: #502aa1;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.violet.buttons .active.button,
.ui.violet.buttons .active.button:active,
.ui.violet.active.button,
.ui.violet.button .active.button:active {
  background-color: #5626bf;
  color: #FFFFFF;
  text-shadow: none;
}

/* Basic */

.ui.basic.violet.buttons .button,
.ui.basic.violet.button {
  box-shadow: 0px 0px 0px 1px #6435C9 inset !important;
  color: #6435C9 !important;
}

.ui.basic.violet.buttons .button:hover,
.ui.basic.violet.button:hover {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #5829bb inset !important;
  color: #5829bb !important;
}

.ui.basic.violet.buttons .button:focus,
.ui.basic.violet.button:focus {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #4f20b5 inset !important;
  color: #5829bb !important;
}

.ui.basic.violet.buttons .active.button,
.ui.basic.violet.active.button {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #5626bf inset !important;
  color: #502aa1 !important;
}

.ui.basic.violet.buttons .button:active,
.ui.basic.violet.button:active {
  box-shadow: 0px 0px 0px 1px #502aa1 inset !important;
  color: #502aa1 !important;
}

.ui.buttons:not(.vertical) > .basic.violet.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.violet.buttons .button,
.ui.inverted.violet.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px #A291FB inset !important;
  color: #A291FB;
}

.ui.inverted.violet.buttons .button:hover,
.ui.inverted.violet.button:hover,
.ui.inverted.violet.buttons .button:focus,
.ui.inverted.violet.button:focus,
.ui.inverted.violet.buttons .button.active,
.ui.inverted.violet.button.active,
.ui.inverted.violet.buttons .button:active,
.ui.inverted.violet.button:active {
  box-shadow: none !important;
  color: #FFFFFF;
}

.ui.inverted.violet.buttons .button:hover,
.ui.inverted.violet.button:hover {
  background-color: #8a73ff;
}

.ui.inverted.violet.buttons .button:focus,
.ui.inverted.violet.button:focus {
  background-color: #7d64ff;
}

.ui.inverted.violet.buttons .active.button,
.ui.inverted.violet.active.button {
  background-color: #8a73ff;
}

.ui.inverted.violet.buttons .button:active,
.ui.inverted.violet.button:active {
  background-color: #7860f9;
}

/* Inverted Basic */

.ui.inverted.violet.basic.buttons .button,
.ui.inverted.violet.buttons .basic.button,
.ui.inverted.violet.basic.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: #FFFFFF !important;
}

.ui.inverted.violet.basic.buttons .button:hover,
.ui.inverted.violet.buttons .basic.button:hover,
.ui.inverted.violet.basic.button:hover {
  box-shadow: 0px 0px 0px 2px #8a73ff inset !important;
  color: #A291FB !important;
}

.ui.inverted.violet.basic.buttons .button:focus,
.ui.inverted.violet.basic.buttons .button:focus,
.ui.inverted.violet.basic.button:focus {
  box-shadow: 0px 0px 0px 2px #7d64ff inset !important;
  color: #A291FB !important;
}

.ui.inverted.violet.basic.buttons .active.button,
.ui.inverted.violet.buttons .basic.active.button,
.ui.inverted.violet.basic.active.button {
  box-shadow: 0px 0px 0px 2px #8a73ff inset !important;
  color: #A291FB !important;
}

.ui.inverted.violet.basic.buttons .button:active,
.ui.inverted.violet.buttons .basic.button:active,
.ui.inverted.violet.basic.button:active {
  box-shadow: 0px 0px 0px 2px #7860f9 inset !important;
  color: #A291FB !important;
}

/*--- Purple ---*/

.ui.purple.buttons .button,
.ui.purple.button {
  background-color: #A333C8;
  color: #FFFFFF;
  text-shadow: none;
  background-image: none;
}

.ui.purple.button {
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.purple.buttons .button:hover,
.ui.purple.button:hover {
  background-color: #9627ba;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.purple.buttons .button:focus,
.ui.purple.button:focus {
  background-color: #8f1eb4;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.purple.buttons .button:active,
.ui.purple.button:active {
  background-color: #82299f;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.purple.buttons .active.button,
.ui.purple.buttons .active.button:active,
.ui.purple.active.button,
.ui.purple.button .active.button:active {
  background-color: #9724be;
  color: #FFFFFF;
  text-shadow: none;
}

/* Basic */

.ui.basic.purple.buttons .button,
.ui.basic.purple.button {
  box-shadow: 0px 0px 0px 1px #A333C8 inset !important;
  color: #A333C8 !important;
}

.ui.basic.purple.buttons .button:hover,
.ui.basic.purple.button:hover {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #9627ba inset !important;
  color: #9627ba !important;
}

.ui.basic.purple.buttons .button:focus,
.ui.basic.purple.button:focus {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #8f1eb4 inset !important;
  color: #9627ba !important;
}

.ui.basic.purple.buttons .active.button,
.ui.basic.purple.active.button {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #9724be inset !important;
  color: #82299f !important;
}

.ui.basic.purple.buttons .button:active,
.ui.basic.purple.button:active {
  box-shadow: 0px 0px 0px 1px #82299f inset !important;
  color: #82299f !important;
}

.ui.buttons:not(.vertical) > .basic.purple.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.purple.buttons .button,
.ui.inverted.purple.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px #DC73FF inset !important;
  color: #DC73FF;
}

.ui.inverted.purple.buttons .button:hover,
.ui.inverted.purple.button:hover,
.ui.inverted.purple.buttons .button:focus,
.ui.inverted.purple.button:focus,
.ui.inverted.purple.buttons .button.active,
.ui.inverted.purple.button.active,
.ui.inverted.purple.buttons .button:active,
.ui.inverted.purple.button:active {
  box-shadow: none !important;
  color: #FFFFFF;
}

.ui.inverted.purple.buttons .button:hover,
.ui.inverted.purple.button:hover {
  background-color: #d65aff;
}

.ui.inverted.purple.buttons .button:focus,
.ui.inverted.purple.button:focus {
  background-color: #d24aff;
}

.ui.inverted.purple.buttons .active.button,
.ui.inverted.purple.active.button {
  background-color: #d65aff;
}

.ui.inverted.purple.buttons .button:active,
.ui.inverted.purple.button:active {
  background-color: #cf40ff;
}

/* Inverted Basic */

.ui.inverted.purple.basic.buttons .button,
.ui.inverted.purple.buttons .basic.button,
.ui.inverted.purple.basic.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: #FFFFFF !important;
}

.ui.inverted.purple.basic.buttons .button:hover,
.ui.inverted.purple.buttons .basic.button:hover,
.ui.inverted.purple.basic.button:hover {
  box-shadow: 0px 0px 0px 2px #d65aff inset !important;
  color: #DC73FF !important;
}

.ui.inverted.purple.basic.buttons .button:focus,
.ui.inverted.purple.basic.buttons .button:focus,
.ui.inverted.purple.basic.button:focus {
  box-shadow: 0px 0px 0px 2px #d24aff inset !important;
  color: #DC73FF !important;
}

.ui.inverted.purple.basic.buttons .active.button,
.ui.inverted.purple.buttons .basic.active.button,
.ui.inverted.purple.basic.active.button {
  box-shadow: 0px 0px 0px 2px #d65aff inset !important;
  color: #DC73FF !important;
}

.ui.inverted.purple.basic.buttons .button:active,
.ui.inverted.purple.buttons .basic.button:active,
.ui.inverted.purple.basic.button:active {
  box-shadow: 0px 0px 0px 2px #cf40ff inset !important;
  color: #DC73FF !important;
}

/*--- Red ---*/

.ui.red.buttons .button,
.ui.red.button {
  background-color: #DB2828;
  color: #FFFFFF;
  text-shadow: none;
  background-image: none;
}

.ui.red.button {
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.red.buttons .button:hover,
.ui.red.button:hover {
  background-color: #d01919;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.red.buttons .button:focus,
.ui.red.button:focus {
  background-color: #ca1010;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.red.buttons .button:active,
.ui.red.button:active {
  background-color: #b21e1e;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.red.buttons .active.button,
.ui.red.buttons .active.button:active,
.ui.red.active.button,
.ui.red.button .active.button:active {
  background-color: #d41515;
  color: #FFFFFF;
  text-shadow: none;
}

/* Basic */

.ui.basic.red.buttons .button,
.ui.basic.red.button {
  box-shadow: 0px 0px 0px 1px #DB2828 inset !important;
  color: #DB2828 !important;
}

.ui.basic.red.buttons .button:hover,
.ui.basic.red.button:hover {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #d01919 inset !important;
  color: #d01919 !important;
}

.ui.basic.red.buttons .button:focus,
.ui.basic.red.button:focus {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #ca1010 inset !important;
  color: #d01919 !important;
}

.ui.basic.red.buttons .active.button,
.ui.basic.red.active.button {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #d41515 inset !important;
  color: #b21e1e !important;
}

.ui.basic.red.buttons .button:active,
.ui.basic.red.button:active {
  box-shadow: 0px 0px 0px 1px #b21e1e inset !important;
  color: #b21e1e !important;
}

.ui.buttons:not(.vertical) > .basic.red.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.red.buttons .button,
.ui.inverted.red.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px #FF695E inset !important;
  color: #FF695E;
}

.ui.inverted.red.buttons .button:hover,
.ui.inverted.red.button:hover,
.ui.inverted.red.buttons .button:focus,
.ui.inverted.red.button:focus,
.ui.inverted.red.buttons .button.active,
.ui.inverted.red.button.active,
.ui.inverted.red.buttons .button:active,
.ui.inverted.red.button:active {
  box-shadow: none !important;
  color: #FFFFFF;
}

.ui.inverted.red.buttons .button:hover,
.ui.inverted.red.button:hover {
  background-color: #ff5144;
}

.ui.inverted.red.buttons .button:focus,
.ui.inverted.red.button:focus {
  background-color: #ff4335;
}

.ui.inverted.red.buttons .active.button,
.ui.inverted.red.active.button {
  background-color: #ff5144;
}

.ui.inverted.red.buttons .button:active,
.ui.inverted.red.button:active {
  background-color: #ff392b;
}

/* Inverted Basic */

.ui.inverted.red.basic.buttons .button,
.ui.inverted.red.buttons .basic.button,
.ui.inverted.red.basic.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: #FFFFFF !important;
}

.ui.inverted.red.basic.buttons .button:hover,
.ui.inverted.red.buttons .basic.button:hover,
.ui.inverted.red.basic.button:hover {
  box-shadow: 0px 0px 0px 2px #ff5144 inset !important;
  color: #FF695E !important;
}

.ui.inverted.red.basic.buttons .button:focus,
.ui.inverted.red.basic.buttons .button:focus,
.ui.inverted.red.basic.button:focus {
  box-shadow: 0px 0px 0px 2px #ff4335 inset !important;
  color: #FF695E !important;
}

.ui.inverted.red.basic.buttons .active.button,
.ui.inverted.red.buttons .basic.active.button,
.ui.inverted.red.basic.active.button {
  box-shadow: 0px 0px 0px 2px #ff5144 inset !important;
  color: #FF695E !important;
}

.ui.inverted.red.basic.buttons .button:active,
.ui.inverted.red.buttons .basic.button:active,
.ui.inverted.red.basic.button:active {
  box-shadow: 0px 0px 0px 2px #ff392b inset !important;
  color: #FF695E !important;
}

/*--- Teal ---*/

.ui.teal.buttons .button,
.ui.teal.button {
  background-color: #00B5AD;
  color: #FFFFFF;
  text-shadow: none;
  background-image: none;
}

.ui.teal.button {
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.teal.buttons .button:hover,
.ui.teal.button:hover {
  background-color: #009c95;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.teal.buttons .button:focus,
.ui.teal.button:focus {
  background-color: #008c86;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.teal.buttons .button:active,
.ui.teal.button:active {
  background-color: #00827c;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.teal.buttons .active.button,
.ui.teal.buttons .active.button:active,
.ui.teal.active.button,
.ui.teal.button .active.button:active {
  background-color: #009c95;
  color: #FFFFFF;
  text-shadow: none;
}

/* Basic */

.ui.basic.teal.buttons .button,
.ui.basic.teal.button {
  box-shadow: 0px 0px 0px 1px #00B5AD inset !important;
  color: #00B5AD !important;
}

.ui.basic.teal.buttons .button:hover,
.ui.basic.teal.button:hover {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #009c95 inset !important;
  color: #009c95 !important;
}

.ui.basic.teal.buttons .button:focus,
.ui.basic.teal.button:focus {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #008c86 inset !important;
  color: #009c95 !important;
}

.ui.basic.teal.buttons .active.button,
.ui.basic.teal.active.button {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #009c95 inset !important;
  color: #00827c !important;
}

.ui.basic.teal.buttons .button:active,
.ui.basic.teal.button:active {
  box-shadow: 0px 0px 0px 1px #00827c inset !important;
  color: #00827c !important;
}

.ui.buttons:not(.vertical) > .basic.teal.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.teal.buttons .button,
.ui.inverted.teal.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px #6DFFFF inset !important;
  color: #6DFFFF;
}

.ui.inverted.teal.buttons .button:hover,
.ui.inverted.teal.button:hover,
.ui.inverted.teal.buttons .button:focus,
.ui.inverted.teal.button:focus,
.ui.inverted.teal.buttons .button.active,
.ui.inverted.teal.button.active,
.ui.inverted.teal.buttons .button:active,
.ui.inverted.teal.button:active {
  box-shadow: none !important;
  color: rgba(0, 0, 0, 0.6);
}

.ui.inverted.teal.buttons .button:hover,
.ui.inverted.teal.button:hover {
  background-color: #54ffff;
}

.ui.inverted.teal.buttons .button:focus,
.ui.inverted.teal.button:focus {
  background-color: #44ffff;
}

.ui.inverted.teal.buttons .active.button,
.ui.inverted.teal.active.button {
  background-color: #54ffff;
}

.ui.inverted.teal.buttons .button:active,
.ui.inverted.teal.button:active {
  background-color: #3affff;
}

/* Inverted Basic */

.ui.inverted.teal.basic.buttons .button,
.ui.inverted.teal.buttons .basic.button,
.ui.inverted.teal.basic.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: #FFFFFF !important;
}

.ui.inverted.teal.basic.buttons .button:hover,
.ui.inverted.teal.buttons .basic.button:hover,
.ui.inverted.teal.basic.button:hover {
  box-shadow: 0px 0px 0px 2px #54ffff inset !important;
  color: #6DFFFF !important;
}

.ui.inverted.teal.basic.buttons .button:focus,
.ui.inverted.teal.basic.buttons .button:focus,
.ui.inverted.teal.basic.button:focus {
  box-shadow: 0px 0px 0px 2px #44ffff inset !important;
  color: #6DFFFF !important;
}

.ui.inverted.teal.basic.buttons .active.button,
.ui.inverted.teal.buttons .basic.active.button,
.ui.inverted.teal.basic.active.button {
  box-shadow: 0px 0px 0px 2px #54ffff inset !important;
  color: #6DFFFF !important;
}

.ui.inverted.teal.basic.buttons .button:active,
.ui.inverted.teal.buttons .basic.button:active,
.ui.inverted.teal.basic.button:active {
  box-shadow: 0px 0px 0px 2px #3affff inset !important;
  color: #6DFFFF !important;
}

/*--- Olive ---*/

.ui.olive.buttons .button,
.ui.olive.button {
  background-color: #B5CC18;
  color: #FFFFFF;
  text-shadow: none;
  background-image: none;
}

.ui.olive.button {
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.olive.buttons .button:hover,
.ui.olive.button:hover {
  background-color: #a7bd0d;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.olive.buttons .button:focus,
.ui.olive.button:focus {
  background-color: #a0b605;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.olive.buttons .button:active,
.ui.olive.button:active {
  background-color: #8d9e13;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.olive.buttons .active.button,
.ui.olive.buttons .active.button:active,
.ui.olive.active.button,
.ui.olive.button .active.button:active {
  background-color: #aac109;
  color: #FFFFFF;
  text-shadow: none;
}

/* Basic */

.ui.basic.olive.buttons .button,
.ui.basic.olive.button {
  box-shadow: 0px 0px 0px 1px #B5CC18 inset !important;
  color: #B5CC18 !important;
}

.ui.basic.olive.buttons .button:hover,
.ui.basic.olive.button:hover {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #a7bd0d inset !important;
  color: #a7bd0d !important;
}

.ui.basic.olive.buttons .button:focus,
.ui.basic.olive.button:focus {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #a0b605 inset !important;
  color: #a7bd0d !important;
}

.ui.basic.olive.buttons .active.button,
.ui.basic.olive.active.button {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #aac109 inset !important;
  color: #8d9e13 !important;
}

.ui.basic.olive.buttons .button:active,
.ui.basic.olive.button:active {
  box-shadow: 0px 0px 0px 1px #8d9e13 inset !important;
  color: #8d9e13 !important;
}

.ui.buttons:not(.vertical) > .basic.olive.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.olive.buttons .button,
.ui.inverted.olive.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px #D9E778 inset !important;
  color: #D9E778;
}

.ui.inverted.olive.buttons .button:hover,
.ui.inverted.olive.button:hover,
.ui.inverted.olive.buttons .button:focus,
.ui.inverted.olive.button:focus,
.ui.inverted.olive.buttons .button.active,
.ui.inverted.olive.button.active,
.ui.inverted.olive.buttons .button:active,
.ui.inverted.olive.button:active {
  box-shadow: none !important;
  color: rgba(0, 0, 0, 0.6);
}

.ui.inverted.olive.buttons .button:hover,
.ui.inverted.olive.button:hover {
  background-color: #d8ea5c;
}

.ui.inverted.olive.buttons .button:focus,
.ui.inverted.olive.button:focus {
  background-color: #daef47;
}

.ui.inverted.olive.buttons .active.button,
.ui.inverted.olive.active.button {
  background-color: #daed59;
}

.ui.inverted.olive.buttons .button:active,
.ui.inverted.olive.button:active {
  background-color: #cddf4d;
}

/* Inverted Basic */

.ui.inverted.olive.basic.buttons .button,
.ui.inverted.olive.buttons .basic.button,
.ui.inverted.olive.basic.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: #FFFFFF !important;
}

.ui.inverted.olive.basic.buttons .button:hover,
.ui.inverted.olive.buttons .basic.button:hover,
.ui.inverted.olive.basic.button:hover {
  box-shadow: 0px 0px 0px 2px #d8ea5c inset !important;
  color: #D9E778 !important;
}

.ui.inverted.olive.basic.buttons .button:focus,
.ui.inverted.olive.basic.buttons .button:focus,
.ui.inverted.olive.basic.button:focus {
  box-shadow: 0px 0px 0px 2px #daef47 inset !important;
  color: #D9E778 !important;
}

.ui.inverted.olive.basic.buttons .active.button,
.ui.inverted.olive.buttons .basic.active.button,
.ui.inverted.olive.basic.active.button {
  box-shadow: 0px 0px 0px 2px #daed59 inset !important;
  color: #D9E778 !important;
}

.ui.inverted.olive.basic.buttons .button:active,
.ui.inverted.olive.buttons .basic.button:active,
.ui.inverted.olive.basic.button:active {
  box-shadow: 0px 0px 0px 2px #cddf4d inset !important;
  color: #D9E778 !important;
}

/*--- Yellow ---*/

.ui.yellow.buttons .button,
.ui.yellow.button {
  background-color: #FBBD08;
  color: #FFFFFF;
  text-shadow: none;
  background-image: none;
}

.ui.yellow.button {
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.yellow.buttons .button:hover,
.ui.yellow.button:hover {
  background-color: #eaae00;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.yellow.buttons .button:focus,
.ui.yellow.button:focus {
  background-color: #daa300;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.yellow.buttons .button:active,
.ui.yellow.button:active {
  background-color: #cd9903;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.yellow.buttons .active.button,
.ui.yellow.buttons .active.button:active,
.ui.yellow.active.button,
.ui.yellow.button .active.button:active {
  background-color: #eaae00;
  color: #FFFFFF;
  text-shadow: none;
}

/* Basic */

.ui.basic.yellow.buttons .button,
.ui.basic.yellow.button {
  box-shadow: 0px 0px 0px 1px #FBBD08 inset !important;
  color: #FBBD08 !important;
}

.ui.basic.yellow.buttons .button:hover,
.ui.basic.yellow.button:hover {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #eaae00 inset !important;
  color: #eaae00 !important;
}

.ui.basic.yellow.buttons .button:focus,
.ui.basic.yellow.button:focus {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #daa300 inset !important;
  color: #eaae00 !important;
}

.ui.basic.yellow.buttons .active.button,
.ui.basic.yellow.active.button {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #eaae00 inset !important;
  color: #cd9903 !important;
}

.ui.basic.yellow.buttons .button:active,
.ui.basic.yellow.button:active {
  box-shadow: 0px 0px 0px 1px #cd9903 inset !important;
  color: #cd9903 !important;
}

.ui.buttons:not(.vertical) > .basic.yellow.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.yellow.buttons .button,
.ui.inverted.yellow.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px #FFE21F inset !important;
  color: #FFE21F;
}

.ui.inverted.yellow.buttons .button:hover,
.ui.inverted.yellow.button:hover,
.ui.inverted.yellow.buttons .button:focus,
.ui.inverted.yellow.button:focus,
.ui.inverted.yellow.buttons .button.active,
.ui.inverted.yellow.button.active,
.ui.inverted.yellow.buttons .button:active,
.ui.inverted.yellow.button:active {
  box-shadow: none !important;
  color: rgba(0, 0, 0, 0.6);
}

.ui.inverted.yellow.buttons .button:hover,
.ui.inverted.yellow.button:hover {
  background-color: #ffdf05;
}

.ui.inverted.yellow.buttons .button:focus,
.ui.inverted.yellow.button:focus {
  background-color: #f5d500;
}

.ui.inverted.yellow.buttons .active.button,
.ui.inverted.yellow.active.button {
  background-color: #ffdf05;
}

.ui.inverted.yellow.buttons .button:active,
.ui.inverted.yellow.button:active {
  background-color: #ebcd00;
}

/* Inverted Basic */

.ui.inverted.yellow.basic.buttons .button,
.ui.inverted.yellow.buttons .basic.button,
.ui.inverted.yellow.basic.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: #FFFFFF !important;
}

.ui.inverted.yellow.basic.buttons .button:hover,
.ui.inverted.yellow.buttons .basic.button:hover,
.ui.inverted.yellow.basic.button:hover {
  box-shadow: 0px 0px 0px 2px #ffdf05 inset !important;
  color: #FFE21F !important;
}

.ui.inverted.yellow.basic.buttons .button:focus,
.ui.inverted.yellow.basic.buttons .button:focus,
.ui.inverted.yellow.basic.button:focus {
  box-shadow: 0px 0px 0px 2px #f5d500 inset !important;
  color: #FFE21F !important;
}

.ui.inverted.yellow.basic.buttons .active.button,
.ui.inverted.yellow.buttons .basic.active.button,
.ui.inverted.yellow.basic.active.button {
  box-shadow: 0px 0px 0px 2px #ffdf05 inset !important;
  color: #FFE21F !important;
}

.ui.inverted.yellow.basic.buttons .button:active,
.ui.inverted.yellow.buttons .basic.button:active,
.ui.inverted.yellow.basic.button:active {
  box-shadow: 0px 0px 0px 2px #ebcd00 inset !important;
  color: #FFE21F !important;
}

/*-------------------
       Primary
--------------------*/

/*--- Standard ---*/

.ui.primary.buttons .button,
.ui.primary.button {
  background-color: #2185D0;
  color: #FFFFFF;
  text-shadow: none;
  background-image: none;
}

.ui.primary.button {
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.primary.buttons .button:hover,
.ui.primary.button:hover {
  background-color: #1678c2;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.primary.buttons .button:focus,
.ui.primary.button:focus {
  background-color: #0d71bb;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.primary.buttons .button:active,
.ui.primary.button:active {
  background-color: #1a69a4;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.primary.buttons .active.button,
.ui.primary.buttons .active.button:active,
.ui.primary.active.button,
.ui.primary.button .active.button:active {
  background-color: #1279c6;
  color: #FFFFFF;
  text-shadow: none;
}

/* Basic */

.ui.basic.primary.buttons .button,
.ui.basic.primary.button {
  box-shadow: 0px 0px 0px 1px #2185D0 inset !important;
  color: #2185D0 !important;
}

.ui.basic.primary.buttons .button:hover,
.ui.basic.primary.button:hover {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #1678c2 inset !important;
  color: #1678c2 !important;
}

.ui.basic.primary.buttons .button:focus,
.ui.basic.primary.button:focus {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #0d71bb inset !important;
  color: #1678c2 !important;
}

.ui.basic.primary.buttons .active.button,
.ui.basic.primary.active.button {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #1279c6 inset !important;
  color: #1a69a4 !important;
}

.ui.basic.primary.buttons .button:active,
.ui.basic.primary.button:active {
  box-shadow: 0px 0px 0px 1px #1a69a4 inset !important;
  color: #1a69a4 !important;
}

.ui.buttons:not(.vertical) > .basic.primary.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.primary.buttons .button,
.ui.inverted.primary.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px #54C8FF inset !important;
  color: #54C8FF;
}

.ui.inverted.primary.buttons .button:hover,
.ui.inverted.primary.button:hover,
.ui.inverted.primary.buttons .button:focus,
.ui.inverted.primary.button:focus,
.ui.inverted.primary.buttons .button.active,
.ui.inverted.primary.button.active,
.ui.inverted.primary.buttons .button:active,
.ui.inverted.primary.button:active {
  box-shadow: none !important;
  color: #FFFFFF;
}

.ui.inverted.primary.buttons .button:hover,
.ui.inverted.primary.button:hover {
  background-color: #3ac0ff;
}

.ui.inverted.primary.buttons .button:focus,
.ui.inverted.primary.button:focus {
  background-color: #2bbbff;
}

.ui.inverted.primary.buttons .active.button,
.ui.inverted.primary.active.button {
  background-color: #3ac0ff;
}

.ui.inverted.primary.buttons .button:active,
.ui.inverted.primary.button:active {
  background-color: #21b8ff;
}

/* Inverted Basic */

.ui.inverted.primary.basic.buttons .button,
.ui.inverted.primary.buttons .basic.button,
.ui.inverted.primary.basic.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: #FFFFFF !important;
}

.ui.inverted.primary.basic.buttons .button:hover,
.ui.inverted.primary.buttons .basic.button:hover,
.ui.inverted.primary.basic.button:hover {
  box-shadow: 0px 0px 0px 2px #3ac0ff inset !important;
  color: #54C8FF !important;
}

.ui.inverted.primary.basic.buttons .button:focus,
.ui.inverted.primary.basic.buttons .button:focus,
.ui.inverted.primary.basic.button:focus {
  box-shadow: 0px 0px 0px 2px #2bbbff inset !important;
  color: #54C8FF !important;
}

.ui.inverted.primary.basic.buttons .active.button,
.ui.inverted.primary.buttons .basic.active.button,
.ui.inverted.primary.basic.active.button {
  box-shadow: 0px 0px 0px 2px #3ac0ff inset !important;
  color: #54C8FF !important;
}

.ui.inverted.primary.basic.buttons .button:active,
.ui.inverted.primary.buttons .basic.button:active,
.ui.inverted.primary.basic.button:active {
  box-shadow: 0px 0px 0px 2px #21b8ff inset !important;
  color: #54C8FF !important;
}

/*-------------------
      Secondary
--------------------*/

/* Standard */

.ui.secondary.buttons .button,
.ui.secondary.button {
  background-color: #1B1C1D;
  color: #FFFFFF;
  text-shadow: none;
  background-image: none;
}

.ui.secondary.button {
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.secondary.buttons .button:hover,
.ui.secondary.button:hover {
  background-color: #27292a;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.secondary.buttons .button:focus,
.ui.secondary.button:focus {
  background-color: #2e3032;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.secondary.buttons .button:active,
.ui.secondary.button:active {
  background-color: #343637;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.secondary.buttons .active.button,
.ui.secondary.buttons .active.button:active,
.ui.secondary.active.button,
.ui.secondary.button .active.button:active {
  background-color: #27292a;
  color: #FFFFFF;
  text-shadow: none;
}

/* Basic */

.ui.basic.secondary.buttons .button,
.ui.basic.secondary.button {
  box-shadow: 0px 0px 0px 1px #1B1C1D inset !important;
  color: #1B1C1D !important;
}

.ui.basic.secondary.buttons .button:hover,
.ui.basic.secondary.button:hover {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #27292a inset !important;
  color: #27292a !important;
}

.ui.basic.secondary.buttons .button:focus,
.ui.basic.secondary.button:focus {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #2e3032 inset !important;
  color: #27292a !important;
}

.ui.basic.secondary.buttons .active.button,
.ui.basic.secondary.active.button {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #27292a inset !important;
  color: #343637 !important;
}

.ui.basic.secondary.buttons .button:active,
.ui.basic.secondary.button:active {
  box-shadow: 0px 0px 0px 1px #343637 inset !important;
  color: #343637 !important;
}

.ui.buttons:not(.vertical) > .basic.primary.button:not(:first-child) {
  margin-left: -1px;
}

/* Inverted */

.ui.inverted.secondary.buttons .button,
.ui.inverted.secondary.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px #545454 inset !important;
  color: #545454;
}

.ui.inverted.secondary.buttons .button:hover,
.ui.inverted.secondary.button:hover,
.ui.inverted.secondary.buttons .button:focus,
.ui.inverted.secondary.button:focus,
.ui.inverted.secondary.buttons .button.active,
.ui.inverted.secondary.button.active,
.ui.inverted.secondary.buttons .button:active,
.ui.inverted.secondary.button:active {
  box-shadow: none !important;
  color: #FFFFFF;
}

.ui.inverted.secondary.buttons .button:hover,
.ui.inverted.secondary.button:hover {
  background-color: #616161;
}

.ui.inverted.secondary.buttons .button:focus,
.ui.inverted.secondary.button:focus {
  background-color: #686868;
}

.ui.inverted.secondary.buttons .active.button,
.ui.inverted.secondary.active.button {
  background-color: #616161;
}

.ui.inverted.secondary.buttons .button:active,
.ui.inverted.secondary.button:active {
  background-color: #6e6e6e;
}

/* Inverted Basic */

.ui.inverted.secondary.basic.buttons .button,
.ui.inverted.secondary.buttons .basic.button,
.ui.inverted.secondary.basic.button {
  background-color: transparent;
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.5) inset !important;
  color: #FFFFFF !important;
}

.ui.inverted.secondary.basic.buttons .button:hover,
.ui.inverted.secondary.buttons .basic.button:hover,
.ui.inverted.secondary.basic.button:hover {
  box-shadow: 0px 0px 0px 2px #616161 inset !important;
  color: #545454 !important;
}

.ui.inverted.secondary.basic.buttons .button:focus,
.ui.inverted.secondary.basic.buttons .button:focus,
.ui.inverted.secondary.basic.button:focus {
  box-shadow: 0px 0px 0px 2px #686868 inset !important;
  color: #545454 !important;
}

.ui.inverted.secondary.basic.buttons .active.button,
.ui.inverted.secondary.buttons .basic.active.button,
.ui.inverted.secondary.basic.active.button {
  box-shadow: 0px 0px 0px 2px #616161 inset !important;
  color: #545454 !important;
}

.ui.inverted.secondary.basic.buttons .button:active,
.ui.inverted.secondary.buttons .basic.button:active,
.ui.inverted.secondary.basic.button:active {
  box-shadow: 0px 0px 0px 2px #6e6e6e inset !important;
  color: #545454 !important;
}

/*---------------
    Positive
----------------*/

/* Standard */

.ui.positive.buttons .button,
.ui.positive.button {
  background-color: #21BA45;
  color: #FFFFFF;
  text-shadow: none;
  background-image: none;
}

.ui.positive.button {
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.positive.buttons .button:hover,
.ui.positive.button:hover {
  background-color: #16ab39;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.positive.buttons .button:focus,
.ui.positive.button:focus {
  background-color: #0ea432;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.positive.buttons .button:active,
.ui.positive.button:active {
  background-color: #198f35;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.positive.buttons .active.button,
.ui.positive.buttons .active.button:active,
.ui.positive.active.button,
.ui.positive.button .active.button:active {
  background-color: #13ae38;
  color: #FFFFFF;
  text-shadow: none;
}

/* Basic */

.ui.basic.positive.buttons .button,
.ui.basic.positive.button {
  box-shadow: 0px 0px 0px 1px #21BA45 inset !important;
  color: #21BA45 !important;
}

.ui.basic.positive.buttons .button:hover,
.ui.basic.positive.button:hover {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #16ab39 inset !important;
  color: #16ab39 !important;
}

.ui.basic.positive.buttons .button:focus,
.ui.basic.positive.button:focus {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #0ea432 inset !important;
  color: #16ab39 !important;
}

.ui.basic.positive.buttons .active.button,
.ui.basic.positive.active.button {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #13ae38 inset !important;
  color: #198f35 !important;
}

.ui.basic.positive.buttons .button:active,
.ui.basic.positive.button:active {
  box-shadow: 0px 0px 0px 1px #198f35 inset !important;
  color: #198f35 !important;
}

.ui.buttons:not(.vertical) > .basic.primary.button:not(:first-child) {
  margin-left: -1px;
}

/*---------------
     Negative
----------------*/

/* Standard */

.ui.negative.buttons .button,
.ui.negative.button {
  background-color: #DB2828;
  color: #FFFFFF;
  text-shadow: none;
  background-image: none;
}

.ui.negative.button {
  box-shadow: 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.negative.buttons .button:hover,
.ui.negative.button:hover {
  background-color: #d01919;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.negative.buttons .button:focus,
.ui.negative.button:focus {
  background-color: #ca1010;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.negative.buttons .button:active,
.ui.negative.button:active {
  background-color: #b21e1e;
  color: #FFFFFF;
  text-shadow: none;
}

.ui.negative.buttons .active.button,
.ui.negative.buttons .active.button:active,
.ui.negative.active.button,
.ui.negative.button .active.button:active {
  background-color: #d41515;
  color: #FFFFFF;
  text-shadow: none;
}

/* Basic */

.ui.basic.negative.buttons .button,
.ui.basic.negative.button {
  box-shadow: 0px 0px 0px 1px #DB2828 inset !important;
  color: #DB2828 !important;
}

.ui.basic.negative.buttons .button:hover,
.ui.basic.negative.button:hover {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #d01919 inset !important;
  color: #d01919 !important;
}

.ui.basic.negative.buttons .button:focus,
.ui.basic.negative.button:focus {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #ca1010 inset !important;
  color: #d01919 !important;
}

.ui.basic.negative.buttons .active.button,
.ui.basic.negative.active.button {
  background: transparent !important;
  box-shadow: 0px 0px 0px 1px #d41515 inset !important;
  color: #b21e1e !important;
}

.ui.basic.negative.buttons .button:active,
.ui.basic.negative.button:active {
  box-shadow: 0px 0px 0px 1px #b21e1e inset !important;
  color: #b21e1e !important;
}

.ui.buttons:not(.vertical) > .basic.primary.button:not(:first-child) {
  margin-left: -1px;
}

/*******************************
            Groups
*******************************/

.ui.buttons {
  display: inline-flex;
  flex-direction: row;
  font-size: 0em;
  vertical-align: baseline;
  margin: 0em 0.25em 0em 0em;
}

.ui.buttons:not(.basic):not(.inverted) {
  box-shadow: none;
}

/* Clearfix */

.ui.buttons:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

/* Standard Group */

.ui.buttons .button {
  flex: 1 0 auto;
  margin: 0em;
  border-radius: 0em;
  margin: 0px 0px 0px 0px;
}

.ui.buttons > .ui.button:not(.basic):not(.inverted),
.ui.buttons:not(.basic):not(.inverted) > .button {
  box-shadow: 0px 0px 0px 1px transparent inset, 0px 0em 0px 0px rgba(34, 36, 38, 0.15) inset;
}

.ui.buttons .button:first-child {
  border-left: none;
  margin-left: 0em;
  border-top-left-radius: 0.28571429rem;
  border-bottom-left-radius: 0.28571429rem;
}

.ui.buttons .button:last-child {
  border-top-right-radius: 0.28571429rem;
  border-bottom-right-radius: 0.28571429rem;
}

/* Vertical  Style */

.ui.vertical.buttons {
  display: inline-flex;
  flex-direction: column;
}

.ui.vertical.buttons .button {
  display: block;
  float: none;
  width: 100%;
  margin: 0px 0px 0px 0px;
  box-shadow: none;
  border-radius: 0em;
}

.ui.vertical.buttons .button:first-child {
  border-top-left-radius: 0.28571429rem;
  border-top-right-radius: 0.28571429rem;
}

.ui.vertical.buttons .button:last-child {
  margin-bottom: 0px;
  border-bottom-left-radius: 0.28571429rem;
  border-bottom-right-radius: 0.28571429rem;
}

.ui.vertical.buttons .button:only-child {
  border-radius: 0.28571429rem;
}


/*--------------
     Toggle
---------------*/

.ui.toggle.checkbox {
  min-height: 1.5rem;
}

/* Input */

.ui.toggle.checkbox input {
  width: 3.5rem;
  height: 1.5rem;
}

/* Label */

.ui.toggle.checkbox .box,
.ui.toggle.checkbox label {
  min-height: 1.5rem;
  padding-left: 4.5rem;
  color: rgba(0, 0, 0, 0.87);
}

.ui.toggle.checkbox label {
  padding-top: 0.15em;
}

/* Switch */

.ui.toggle.checkbox .box:before,
.ui.toggle.checkbox label:before {
  display: block;
  position: absolute;
  content: '';
  z-index: 1;
  transform: none;
  border: none;
  top: 0rem;
  background: rgba(0, 0, 0, 0.05);
  box-shadow: none;
  width: 3.5rem;
  height: 1.5rem;
  border-radius: 500rem;
}

/* Handle */

.ui.toggle.checkbox .box:after,
.ui.toggle.checkbox label:after {
  background: #FFFFFF linear-gradient(transparent, rgba(0, 0, 0, 0.05));
  position: absolute;
  content: '' !important;
  opacity: 1;
  z-index: 2;
  border: none;
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15), 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
  width: 1.5rem;
  height: 1.5rem;
  top: 0rem;
  left: 0em;
  border-radius: 500rem;
  transition: background 0.3s ease, left 0.3s ease;
}

.ui.toggle.checkbox input ~ .box:after,
.ui.toggle.checkbox input ~ label:after {
  left: -0.05rem;
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15), 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
}

/* Focus */

.ui.toggle.checkbox input:focus ~ .box:before,
.ui.toggle.checkbox input:focus ~ label:before {
  background-color: rgba(0, 0, 0, 0.15);
  border: none;
}

/* Hover */

.ui.toggle.checkbox .box:hover::before,
.ui.toggle.checkbox label:hover::before {
  background-color: rgba(0, 0, 0, 0.15);
  border: none;
}

/* Active */

.ui.toggle.checkbox input:checked ~ .box,
.ui.toggle.checkbox input:checked ~ label {
  color: rgba(0, 0, 0, 0.95) !important;
}

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: #2185D0 !important;
}

.ui.toggle.checkbox input:checked ~ .box:after,
.ui.toggle.checkbox input:checked ~ label:after {
  left: 2.15rem;
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15), 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
}

/* Active Focus */

.ui.toggle.checkbox input:focus:checked ~ .box,
.ui.toggle.checkbox input:focus:checked ~ label {
  color: rgba(0, 0, 0, 0.95) !important;
}

.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
  background-color: #0d71bb !important;
}

/*******************************
            Variations
*******************************/

/*--------------
     Fitted
---------------*/

.ui.fitted.checkbox .box,
.ui.fitted.checkbox label {
  padding-left: 0em !important;
}

.ui.fitted.toggle.checkbox,
.ui.fitted.toggle.checkbox {
  width: 3.5rem;
}

.ui.fitted.slider.checkbox,
.ui.fitted.slider.checkbox {
  width: 3.5rem;
}

/*--------------
     Toggle
---------------*/

/* Toggle (Modifies active state to give affordances) */

.ui.toggle.buttons .active.button,
.ui.buttons .button.toggle.active,
.ui.button.toggle.active {
  background-color: #21BA45 !important;
  box-shadow: none !important;
  text-shadow: none;
  color: #FFFFFF !important;
}

.ui.button.toggle.active:hover {
  background-color: #16ab39 !important;
  text-shadow: none;
  color: #FFFFFF !important;
}

i.icon.toggle.off:before {
  content: "\f204";
}

i.icon.toggle.on:before {
  content: "\f205";
}


/*******************************
           Checkbox
*******************************/

/*--------------
    Content
---------------*/

.ui.checkbox {
  position: relative;
  display: inline-block;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  outline: none;
  vertical-align: baseline;
  font-style: normal;
  min-height: 17px;
  font-size: 1rem;
  line-height: 17px;
  min-width: 17px;
}

/* HTML Checkbox */

.ui.checkbox input[type="checkbox"],
.ui.checkbox input[type="radio"] {
  cursor: pointer;
  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0 !important;
  outline: none;
  z-index: 3;
  width: 17px;
  height: 17px;
}

/*--------------
      Box
---------------*/

.ui.checkbox .box,
.ui.checkbox label {
  cursor: auto;
  position: relative;
  display: block;
  padding-left: 1.85714em;
  outline: none;
  font-size: 1em;
}

.ui.checkbox .box:before,
.ui.checkbox label:before {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 17px;
  height: 17px;
  content: '';
  background: #FFFFFF;
  border-radius: 0.21428571rem;
  transition: border 0.1s ease, opacity 0.1s ease, transform 0.1s ease, box-shadow 0.1s ease;
  border: 1px solid #D4D4D5;
}

/*--------------
    Checkmark
---------------*/

.ui.checkbox .box:after,
.ui.checkbox label:after {
  position: absolute;
  font-size: 14px;
  top: 0px;
  left: 0px;
  width: 17px;
  height: 17px;
  text-align: center;
  opacity: 0;
  color: rgba(0, 0, 0, 0.87);
  transition: border 0.1s ease, opacity 0.1s ease, transform 0.1s ease, box-shadow 0.1s ease;
}

/*--------------
      Label
---------------*/

/* Inside */

.ui.checkbox label,
.ui.checkbox + label {
  color: rgba(0, 0, 0, 0.87);
  transition: color 0.1s ease;
}

/* Outside */

.ui.checkbox + label {
  vertical-align: middle;
}

/*******************************
           States
*******************************/

/*--------------
      Hover
---------------*/

.ui.checkbox .box:hover::before,
.ui.checkbox label:hover::before {
  background: #FFFFFF;
  border-color: rgba(34, 36, 38, 0.35);
}

.ui.checkbox label:hover,
.ui.checkbox + label:hover {
  color: rgba(0, 0, 0, 0.8);
}

/*--------------
      Down
---------------*/

.ui.checkbox .box:active::before,
.ui.checkbox label:active::before {
  background: #F9FAFB;
  border-color: rgba(34, 36, 38, 0.35);
}

.ui.checkbox .box:active::after,
.ui.checkbox label:active::after {
  color: rgba(0, 0, 0, 0.95);
}

.ui.checkbox input:active ~ label {
  color: rgba(0, 0, 0, 0.95);
}

/*--------------
     Focus
---------------*/

.ui.checkbox input:focus ~ .box:before,
.ui.checkbox input:focus ~ label:before {
  background: #FFFFFF;
  border-color: #96C8DA;
}

.ui.checkbox input:focus ~ .box:after,
.ui.checkbox input:focus ~ label:after {
  color: rgba(0, 0, 0, 0.95);
}

.ui.checkbox input:focus ~ label {
  color: rgba(0, 0, 0, 0.95);
}

/*--------------
     Active
---------------*/

.ui.checkbox input:checked ~ .box:before,
.ui.checkbox input:checked ~ label:before {
  background: #FFFFFF;
  border-color: rgba(34, 36, 38, 0.35);
}

.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
  opacity: 1;
  color: rgba(0, 0, 0, 0.95);
}

/*--------------
  Indeterminate
---------------*/

.ui.checkbox input:not([type=radio]):indeterminate ~ .box:before,
.ui.checkbox input:not([type=radio]):indeterminate ~ label:before {
  background: #FFFFFF;
  border-color: rgba(34, 36, 38, 0.35);
}

.ui.checkbox input:not([type=radio]):indeterminate ~ .box:after,
.ui.checkbox input:not([type=radio]):indeterminate ~ label:after {
  opacity: 1;
  color: rgba(0, 0, 0, 0.95);
}

/*--------------
  Active Focus
---------------*/

.ui.checkbox input:not([type=radio]):indeterminate:focus ~ .box:before,
.ui.checkbox input:not([type=radio]):indeterminate:focus ~ label:before,
.ui.checkbox input:checked:focus ~ .box:before,
.ui.checkbox input:checked:focus ~ label:before {
  background: #FFFFFF;
  border-color: #96C8DA;
}

.ui.checkbox input:not([type=radio]):indeterminate:focus ~ .box:after,
.ui.checkbox input:not([type=radio]):indeterminate:focus ~ label:after,
.ui.checkbox input:checked:focus ~ .box:after,
.ui.checkbox input:checked:focus ~ label:after {
  color: rgba(0, 0, 0, 0.95);
}

/*--------------
    Read-Only
---------------*/

.ui.read-only.checkbox,
.ui.read-only.checkbox label {
  cursor: default;
}

/*--------------
     Disabled
---------------*/

.ui.disabled.checkbox .box:after,
.ui.disabled.checkbox label,
.ui.checkbox input[disabled] ~ .box:after,
.ui.checkbox input[disabled] ~ label {
  cursor: default !important;
  opacity: 0.5;
  color: #000000;
}

/*--------------
     Hidden
---------------*/

/* Initialized checkbox moves input below element
 to prevent manually triggering */

.ui.checkbox input.hidden {
  z-index: -1;
}

/* Selectable Label */

.ui.checkbox input.hidden + label {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/*******************************
             Types
*******************************/

/*--------------
     Radio
---------------*/

.ui.radio.checkbox {
  min-height: 15px;
}

.ui.radio.checkbox .box,
.ui.radio.checkbox label {
  padding-left: 1.85714em;
}

/* Box */

.ui.radio.checkbox .box:before,
.ui.radio.checkbox label:before {
  content: '';
  transform: none;
  width: 15px;
  height: 15px;
  border-radius: 500rem;
  top: 1px;
  left: 0px;
}

/* Bullet */

.ui.radio.checkbox .box:after,
.ui.radio.checkbox label:after {
  border: none;
  content: '' !important;
  width: 15px;
  height: 15px;
  line-height: 15px;
}

/* Radio Checkbox */

.ui.radio.checkbox .box:after,
.ui.radio.checkbox label:after {
  top: 1px;
  left: 0px;
  width: 15px;
  height: 15px;
  border-radius: 500rem;
  transform: scale(0.46666667);
  background-color: rgba(0, 0, 0, 0.87);
}

/* Focus */

.ui.radio.checkbox input:focus ~ .box:before,
.ui.radio.checkbox input:focus ~ label:before {
  background-color: #FFFFFF;
}

.ui.radio.checkbox input:focus ~ .box:after,
.ui.radio.checkbox input:focus ~ label:after {
  background-color: rgba(0, 0, 0, 0.95);
}

/* Indeterminate */

.ui.radio.checkbox input:indeterminate ~ .box:after,
.ui.radio.checkbox input:indeterminate ~ label:after {
  opacity: 0;
}

/* Active */

.ui.radio.checkbox input:checked ~ .box:before,
.ui.radio.checkbox input:checked ~ label:before {
  background-color: #FFFFFF;
}

.ui.radio.checkbox input:checked ~ .box:after,
.ui.radio.checkbox input:checked ~ label:after {
  background-color: rgba(0, 0, 0, 0.95);
}

/* Active Focus */

.ui.radio.checkbox input:focus:checked ~ .box:before,
.ui.radio.checkbox input:focus:checked ~ label:before {
  background-color: #FFFFFF;
}

.ui.radio.checkbox input:focus:checked ~ .box:after,
.ui.radio.checkbox input:focus:checked ~ label:after {
  background-color: rgba(0, 0, 0, 0.95);
}

/*--------------
     Slider
---------------*/

.ui.slider.checkbox {
  min-height: 1.25rem;
}

/* Input */

.ui.slider.checkbox input {
  width: 3.5rem;
  height: 1.25rem;
}

/* Label */

.ui.slider.checkbox .box,
.ui.slider.checkbox label {
  padding-left: 4.5rem;
  line-height: 1rem;
  color: rgba(0, 0, 0, 0.4);
}

/* Line */

.ui.slider.checkbox .box:before,
.ui.slider.checkbox label:before {
  display: block;
  position: absolute;
  content: '';
  border: none !important;
  left: 0em;
  z-index: 1;
  top: 0.4rem;
  background-color: rgba(0, 0, 0, 0.05);
  width: 3.5rem;
  height: 0.21428571rem;
  transform: none;
  border-radius: 500rem;
  transition: background 0.3s ease;
}

/* Handle */

.ui.slider.checkbox .box:after,
.ui.slider.checkbox label:after {
  background: #FFFFFF linear-gradient(transparent, rgba(0, 0, 0, 0.05));
  position: absolute;
  content: '' !important;
  opacity: 1;
  z-index: 2;
  border: none;
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15), 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
  width: 1.5rem;
  height: 1.5rem;
  top: -0.25rem;
  left: 0em;
  transform: none;
  border-radius: 500rem;
  transition: left 0.3s ease;
}

/* Focus */

.ui.slider.checkbox input:focus ~ .box:before,
.ui.slider.checkbox input:focus ~ label:before {
  background-color: rgba(0, 0, 0, 0.15);
  border: none;
}

/* Hover */

.ui.slider.checkbox .box:hover,
.ui.slider.checkbox label:hover {
  color: rgba(0, 0, 0, 0.8);
}

.ui.slider.checkbox .box:hover::before,
.ui.slider.checkbox label:hover::before {
  background: rgba(0, 0, 0, 0.15);
}

/* Active */

.ui.slider.checkbox input:checked ~ .box,
.ui.slider.checkbox input:checked ~ label {
  color: rgba(0, 0, 0, 0.95) !important;
}

.ui.slider.checkbox input:checked ~ .box:before,
.ui.slider.checkbox input:checked ~ label:before {
  background-color: #545454 !important;
}

.ui.slider.checkbox input:checked ~ .box:after,
.ui.slider.checkbox input:checked ~ label:after {
  left: 2rem;
}

/* Active Focus */

.ui.slider.checkbox input:focus:checked ~ .box,
.ui.slider.checkbox input:focus:checked ~ label {
  color: rgba(0, 0, 0, 0.95) !important;
}

.ui.slider.checkbox input:focus:checked ~ .box:before,
.ui.slider.checkbox input:focus:checked ~ label:before {
  background-color: #000000 !important;
}

/*--------------
     Toggle
---------------*/

.ui.toggle.checkbox {
  min-height: 1.5rem;
}

/* Input */

.ui.toggle.checkbox input {
  width: 3.5rem;
  height: 1.5rem;
}

/* Label */

.ui.toggle.checkbox .box,
.ui.toggle.checkbox label {
  min-height: 1.5rem;
  padding-left: 4.5rem;
  color: rgba(0, 0, 0, 0.87);
}

.ui.toggle.checkbox label {
  padding-top: 0.15em;
}

/* Switch */

.ui.toggle.checkbox .box:before,
.ui.toggle.checkbox label:before {
  display: block;
  position: absolute;
  content: '';
  z-index: 1;
  transform: none;
  border: none;
  top: 0rem;
  background: rgba(0, 0, 0, 0.05);
  box-shadow: none;
  width: 3.5rem;
  height: 1.5rem;
  border-radius: 500rem;
}

/* Handle */

.ui.toggle.checkbox .box:after,
.ui.toggle.checkbox label:after {
  background: #FFFFFF linear-gradient(transparent, rgba(0, 0, 0, 0.05));
  position: absolute;
  content: '' !important;
  opacity: 1;
  z-index: 2;
  border: none;
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15), 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
  width: 1.5rem;
  height: 1.5rem;
  top: 0rem;
  left: 0em;
  border-radius: 500rem;
  transition: background 0.3s ease, left 0.3s ease;
}

.ui.toggle.checkbox input ~ .box:after,
.ui.toggle.checkbox input ~ label:after {
  left: -0.05rem;
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15), 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
}

/* Focus */

.ui.toggle.checkbox input:focus ~ .box:before,
.ui.toggle.checkbox input:focus ~ label:before {
  background-color: rgba(0, 0, 0, 0.15);
  border: none;
}

/* Hover */

.ui.toggle.checkbox .box:hover::before,
.ui.toggle.checkbox label:hover::before {
  background-color: rgba(0, 0, 0, 0.15);
  border: none;
}

/* Active */

.ui.toggle.checkbox input:checked ~ .box,
.ui.toggle.checkbox input:checked ~ label {
  color: rgba(0, 0, 0, 0.95) !important;
}

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: #2185D0 !important;
}

.ui.toggle.checkbox input:checked ~ .box:after,
.ui.toggle.checkbox input:checked ~ label:after {
  left: 2.15rem;
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15), 0px 0px 0px 1px rgba(34, 36, 38, 0.15) inset;
}

/* Active Focus */

.ui.toggle.checkbox input:focus:checked ~ .box,
.ui.toggle.checkbox input:focus:checked ~ label {
  color: rgba(0, 0, 0, 0.95) !important;
}

.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
  background-color: #0d71bb !important;
}

/*******************************
            Variations
*******************************/

/*--------------
     Fitted
---------------*/

.ui.fitted.checkbox .box,
.ui.fitted.checkbox label {
  padding-left: 0em !important;
}

.ui.fitted.toggle.checkbox,
.ui.fitted.toggle.checkbox {
  width: 3.5rem;
}

.ui.fitted.slider.checkbox,
.ui.fitted.slider.checkbox {
  width: 3.5rem;
}

/*******************************
         Theme Overrides
*******************************/

@font-face {
  font-family: 'Checkbox';
  src: url(data:application/x-font-ttf;charset=utf-8;base64,AAEAAAALAIAAAwAwT1MvMg8SBD8AAAC8AAAAYGNtYXAYVtCJAAABHAAAAFRnYXNwAAAAEAAAAXAAAAAIZ2x5Zn4huwUAAAF4AAABYGhlYWQGPe1ZAAAC2AAAADZoaGVhB30DyAAAAxAAAAAkaG10eBBKAEUAAAM0AAAAHGxvY2EAmgESAAADUAAAABBtYXhwAAkALwAAA2AAAAAgbmFtZSC8IugAAAOAAAABknBvc3QAAwAAAAAFFAAAACAAAwMTAZAABQAAApkCzAAAAI8CmQLMAAAB6wAzAQkAAAAAAAAAAAAAAAAAAAABEAAAAAAAAAAAAAAAAAAAAABAAADoAgPA/8AAQAPAAEAAAAABAAAAAAAAAAAAAAAgAAAAAAADAAAAAwAAABwAAQADAAAAHAADAAEAAAAcAAQAOAAAAAoACAACAAIAAQAg6AL//f//AAAAAAAg6AD//f//AAH/4xgEAAMAAQAAAAAAAAAAAAAAAQAB//8ADwABAAAAAAAAAAAAAgAANzkBAAAAAAEAAAAAAAAAAAACAAA3OQEAAAAAAQAAAAAAAAAAAAIAADc5AQAAAAABAEUAUQO7AvgAGgAAARQHAQYjIicBJjU0PwE2MzIfAQE2MzIfARYVA7sQ/hQQFhcQ/uMQEE4QFxcQqAF2EBcXEE4QAnMWEP4UEBABHRAXFhBOEBCoAXcQEE4QFwAAAAABAAABbgMlAkkAFAAAARUUBwYjISInJj0BNDc2MyEyFxYVAyUQEBf9SRcQEBAQFwK3FxAQAhJtFxAQEBAXbRcQEBAQFwAAAAABAAAASQMlA24ALAAAARUUBwYrARUUBwYrASInJj0BIyInJj0BNDc2OwE1NDc2OwEyFxYdATMyFxYVAyUQEBfuEBAXbhYQEO4XEBAQEBfuEBAWbhcQEO4XEBACEm0XEBDuFxAQEBAX7hAQF20XEBDuFxAQEBAX7hAQFwAAAQAAAAIAAHRSzT9fDzz1AAsEAAAAAADRsdR3AAAAANGx1HcAAAAAA7sDbgAAAAgAAgAAAAAAAAABAAADwP/AAAAEAAAAAAADuwABAAAAAAAAAAAAAAAAAAAABwQAAAAAAAAAAAAAAAIAAAAEAABFAyUAAAMlAAAAAAAAAAoAFAAeAE4AcgCwAAEAAAAHAC0AAQAAAAAAAgAAAAAAAAAAAAAAAAAAAAAAAAAOAK4AAQAAAAAAAQAIAAAAAQAAAAAAAgAHAGkAAQAAAAAAAwAIADkAAQAAAAAABAAIAH4AAQAAAAAABQALABgAAQAAAAAABgAIAFEAAQAAAAAACgAaAJYAAwABBAkAAQAQAAgAAwABBAkAAgAOAHAAAwABBAkAAwAQAEEAAwABBAkABAAQAIYAAwABBAkABQAWACMAAwABBAkABgAQAFkAAwABBAkACgA0ALBDaGVja2JveABDAGgAZQBjAGsAYgBvAHhWZXJzaW9uIDIuMABWAGUAcgBzAGkAbwBuACAAMgAuADBDaGVja2JveABDAGgAZQBjAGsAYgBvAHhDaGVja2JveABDAGgAZQBjAGsAYgBvAHhSZWd1bGFyAFIAZQBnAHUAbABhAHJDaGVja2JveABDAGgAZQBjAGsAYgBvAHhGb250IGdlbmVyYXRlZCBieSBJY29Nb29uLgBGAG8AbgB0ACAAZwBlAG4AZQByAGEAdABlAGQAIABiAHkAIABJAGMAbwBNAG8AbwBuAC4AAAADAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA) format('truetype');
}

/* Checkmark */

.ui.checkbox label:after,
.ui.checkbox .box:after {
  font-family: 'Checkbox';
}

/* Checked */

.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
  content: '\e800';
}

/* Indeterminate */

.ui.checkbox input:indeterminate ~ .box:after,
.ui.checkbox input:indeterminate ~ label:after {
  font-size: 12px;
  content: '\e801';
}


/*******************************
            Loader
*******************************/

/* Standard Size */

.ui.loader {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: 0px;
  text-align: center;
  z-index: 1000;
  transform: translateX(-50%) translateY(-50%);
}

/* Static Shape */

.ui.loader:before {
  position: absolute;
  content: '';
  top: 0%;
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: 500rem;
  border: 0.2em solid rgba(0, 0, 0, 0.1);
}

/* Active Shape */

.ui.loader:after {
  position: absolute;
  content: '';
  top: 0%;
  left: 50%;
  width: 100%;
  height: 100%;
  -webkit-animation: loader 0.6s linear;
  animation: loader 0.6s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 500rem;
  border-color: #767676 transparent transparent;
  border-style: solid;
  border-width: 0.2em;
  box-shadow: 0px 0px 0px 1px transparent;
}

/* Active Animation */

@-webkit-keyframes loader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* Sizes */

.ui.mini.loader:before,
.ui.mini.loader:after {
  width: 1rem;
  height: 1rem;
  margin: 0em 0em 0em -0.5rem;
}

.ui.tiny.loader:before,
.ui.tiny.loader:after {
  width: 1.14285714rem;
  height: 1.14285714rem;
  margin: 0em 0em 0em -0.57142857rem;
}

.ui.small.loader:before,
.ui.small.loader:after {
  width: 1.71428571rem;
  height: 1.71428571rem;
  margin: 0em 0em 0em -0.85714286rem;
}

.ui.loader:before,
.ui.loader:after {
  width: 2.28571429rem;
  height: 2.28571429rem;
  margin: 0em 0em 0em -1.14285714rem;
}

.ui.large.loader:before,
.ui.large.loader:after {
  width: 3.42857143rem;
  height: 3.42857143rem;
  margin: 0em 0em 0em -1.71428571rem;
}

.ui.big.loader:before,
.ui.big.loader:after {
  width: 3.71428571rem;
  height: 3.71428571rem;
  margin: 0em 0em 0em -1.85714286rem;
}

.ui.huge.loader:before,
.ui.huge.loader:after {
  width: 4.14285714rem;
  height: 4.14285714rem;
  margin: 0em 0em 0em -2.07142857rem;
}

.ui.massive.loader:before,
.ui.massive.loader:after {
  width: 4.57142857rem;
  height: 4.57142857rem;
  margin: 0em 0em 0em -2.28571429rem;
}

/*-------------------
      Coupling
--------------------*/

/* Show inside active dimmer */

.ui.dimmer .loader {
  display: block;
}

/* Black Dimmer */

.ui.dimmer .ui.loader {
  color: rgba(255, 255, 255, 0.9);
}

.ui.dimmer .ui.loader:before {
  border-color: rgba(255, 255, 255, 0.15);
}

.ui.dimmer .ui.loader:after {
  border-color: #FFFFFF transparent transparent;
}

/* White Dimmer (Inverted) */

.ui.inverted.dimmer .ui.loader {
  color: rgba(0, 0, 0, 0.87);
}

.ui.inverted.dimmer .ui.loader:before {
  border-color: rgba(0, 0, 0, 0.1);
}

.ui.inverted.dimmer .ui.loader:after {
  border-color: #767676 transparent transparent;
}

/*******************************
             Types
*******************************/

/*-------------------
        Text
--------------------*/

.ui.text.loader {
  width: auto !important;
  height: auto !important;
  text-align: center;
  font-style: normal;
}

/*******************************
            States
*******************************/

.ui.indeterminate.loader:after {
  animation-direction: reverse;
  -webkit-animation-duration: 1.2s;
  animation-duration: 1.2s;
}

.ui.loader.active,
.ui.loader.visible {
  display: block;
}

.ui.loader.disabled,
.ui.loader.hidden {
  display: none;
}

/*******************************
            Variations
*******************************/

/*-------------------
        Sizes
--------------------*/

/* Loader */

.ui.inverted.dimmer .ui.mini.loader,
.ui.mini.loader {
  width: 1rem;
  height: 1rem;
  font-size: 0.78571429em;
}

.ui.inverted.dimmer .ui.tiny.loader,
.ui.tiny.loader {
  width: 1.14285714rem;
  height: 1.14285714rem;
  font-size: 0.85714286em;
}

.ui.inverted.dimmer .ui.small.loader,
.ui.small.loader {
  width: 1.71428571rem;
  height: 1.71428571rem;
  font-size: 0.92857143em;
}

.ui.inverted.dimmer .ui.loader,
.ui.loader {
  width: 2.28571429rem;
  height: 2.28571429rem;
  font-size: 1em;
}

.ui.inverted.dimmer .ui.large.loader,
.ui.large.loader {
  width: 3.42857143rem;
  height: 3.42857143rem;
  font-size: 1.14285714em;
}

.ui.inverted.dimmer .ui.big.loader,
.ui.big.loader {
  width: 3.71428571rem;
  height: 3.71428571rem;
  font-size: 1.28571429em;
}

.ui.inverted.dimmer .ui.huge.loader,
.ui.huge.loader {
  width: 4.14285714rem;
  height: 4.14285714rem;
  font-size: 1.42857143em;
}

.ui.inverted.dimmer .ui.massive.loader,
.ui.massive.loader {
  width: 4.57142857rem;
  height: 4.57142857rem;
  font-size: 1.71428571em;
}

/* Text Loader */

.ui.mini.text.loader {
  min-width: 1rem;
  padding-top: 1.78571429rem;
}

.ui.tiny.text.loader {
  min-width: 1.14285714rem;
  padding-top: 1.92857143rem;
}

.ui.small.text.loader {
  min-width: 1.71428571rem;
  padding-top: 2.5rem;
}

.ui.text.loader {
  min-width: 2.28571429rem;
  padding-top: 3.07142857rem;
}

.ui.large.text.loader {
  min-width: 3.42857143rem;
  padding-top: 4.21428571rem;
}

.ui.big.text.loader {
  min-width: 3.71428571rem;
  padding-top: 4.5rem;
}

.ui.huge.text.loader {
  min-width: 4.14285714rem;
  padding-top: 4.92857143rem;
}

.ui.massive.text.loader {
  min-width: 4.57142857rem;
  padding-top: 5.35714286rem;
}

/*-------------------
       Inverted
--------------------*/

.ui.inverted.loader {
  color: rgba(255, 255, 255, 0.9);
}

.ui.inverted.loader:before {
  border-color: rgba(255, 255, 255, 0.15);
}

.ui.inverted.loader:after {
  border-top-color: #FFFFFF;
}

/*-------------------
       Inline
--------------------*/

.ui.inline.loader {
  position: relative;
  vertical-align: middle;
  margin: 0em;
  left: 0em;
  top: 0em;
  transform: none;
}

.ui.inline.loader.active,
.ui.inline.loader.visible {
  display: inline-block;
}

/* Centered Inline */

.ui.centered.inline.loader.active,
.ui.centered.inline.loader.visible {
  display: block;
  margin-left: auto;
  margin-right: auto;
}


/*******************************
            Segment
*******************************/

.ui.segment {
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
  margin: 1rem 0em;
  padding: 1em 1em;
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
}

.ui.segment:first-child {
  margin-top: 0em;
}

.ui.segment:last-child {
  margin-bottom: 0em;
}

/* Vertical */

.ui.vertical.segment {
  margin: 0em;
  padding-left: 0em;
  padding-right: 0em;
  background: none transparent;
  border-radius: 0px;
  box-shadow: none;
  border: none;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

.ui.vertical.segment:last-child {
  border-bottom: none;
}

/*-------------------
    Loose Coupling
--------------------*/

/* Header */

.ui.inverted.segment > .ui.header {
  color: #FFFFFF;
}

/* Label */

.ui[class*="bottom attached"].segment > [class*="top attached"].label {
  border-top-left-radius: 0em;
  border-top-right-radius: 0em;
}

.ui[class*="top attached"].segment > [class*="bottom attached"].label {
  border-bottom-left-radius: 0em;
  border-bottom-right-radius: 0em;
}

.ui.attached.segment:not(.top):not(.bottom) > [class*="top attached"].label {
  border-top-left-radius: 0em;
  border-top-right-radius: 0em;
}

.ui.attached.segment:not(.top):not(.bottom) > [class*="bottom attached"].label {
  border-bottom-left-radius: 0em;
  border-bottom-right-radius: 0em;
}

/* Grid */

.ui.page.grid.segment,
.ui.grid > .row > .ui.segment.column,
.ui.grid > .ui.segment.column {
  padding-top: 2em;
  padding-bottom: 2em;
}

.ui.grid.segment {
  margin: 1rem 0em;
  border-radius: 0.28571429rem;
}

/* Table */

.ui.basic.table.segment {
  background: #FFFFFF;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
}

.ui[class*="very basic"].table.segment {
  padding: 1em 1em;
}

/*******************************
             Types
*******************************/

/*-------------------
     Placeholder
--------------------*/

.ui.placeholder.segment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  max-width: initial;
  -webkit-animation: none;
  animation: none;
  overflow: visible;
  padding: 1em 1em;
  min-height: 18rem;
  background: #F9FAFB;
  border-color: rgba(34, 36, 38, 0.15);
  box-shadow: 0px 2px 25px 0 rgba(34, 36, 38, 0.05) inset;
}

.ui.placeholder.segment .button,
.ui.placeholder.segment textarea {
  display: block;
}

.ui.placeholder.segment .field,
.ui.placeholder.segment textarea,
.ui.placeholder.segment > .ui.input,
.ui.placeholder.segment .button {
  max-width: 15rem;
  margin-left: auto;
  margin-right: auto;
}

.ui.placeholder.segment .column .button,
.ui.placeholder.segment .column .field,
.ui.placeholder.segment .column textarea,
.ui.placeholder.segment .column > .ui.input {
  max-width: 15rem;
  margin-left: auto;
  margin-right: auto;
}

.ui.placeholder.segment > .inline {
  align-self: center;
}

.ui.placeholder.segment > .inline > .button {
  display: inline-block;
  width: auto;
  margin: 0px 0.35714286rem 0px 0px;
}

.ui.placeholder.segment > .inline > .button:last-child {
  margin-right: 0px;
}

/*-------------------
        Piled
--------------------*/

.ui.piled.segments,
.ui.piled.segment {
  margin: 3em 0em;
  box-shadow: '';
  z-index: auto;
}

.ui.piled.segment:first-child {
  margin-top: 0em;
}

.ui.piled.segment:last-child {
  margin-bottom: 0em;
}

.ui.piled.segments:after,
.ui.piled.segments:before,
.ui.piled.segment:after,
.ui.piled.segment:before {
  background-color: #FFFFFF;
  visibility: visible;
  content: '';
  display: block;
  height: 100%;
  left: 0px;
  position: absolute;
  width: 100%;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: '';
}

.ui.piled.segments:before,
.ui.piled.segment:before {
  transform: rotate(-1.2deg);
  top: 0;
  z-index: -2;
}

.ui.piled.segments:after,
.ui.piled.segment:after {
  transform: rotate(1.2deg);
  top: 0;
  z-index: -1;
}

/* Piled Attached */

.ui[class*="top attached"].piled.segment {
  margin-top: 3em;
  margin-bottom: 0em;
}

.ui.piled.segment[class*="top attached"]:first-child {
  margin-top: 0em;
}

.ui.piled.segment[class*="bottom attached"] {
  margin-top: 0em;
  margin-bottom: 3em;
}

.ui.piled.segment[class*="bottom attached"]:last-child {
  margin-bottom: 0em;
}

/*-------------------
       Stacked
--------------------*/

.ui.stacked.segment {
  padding-bottom: 1.4em;
}

.ui.stacked.segments:before,
.ui.stacked.segments:after,
.ui.stacked.segment:before,
.ui.stacked.segment:after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0%;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  background: rgba(0, 0, 0, 0.03);
  width: 100%;
  height: 6px;
  visibility: visible;
}

.ui.stacked.segments:before,
.ui.stacked.segment:before {
  display: none;
}

/* Add additional page */

.ui.tall.stacked.segments:before,
.ui.tall.stacked.segment:before {
  display: block;
  bottom: 0px;
}

/* Inverted */

.ui.stacked.inverted.segments:before,
.ui.stacked.inverted.segments:after,
.ui.stacked.inverted.segment:before,
.ui.stacked.inverted.segment:after {
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(34, 36, 38, 0.35);
}

/*-------------------
       Padded
--------------------*/

.ui.padded.segment {
  padding: 1.5em;
}

.ui[class*="very padded"].segment {
  padding: 3em;
}

/* Padded vertical */

.ui.padded.segment.vertical.segment,
.ui[class*="very padded"].vertical.segment {
  padding-left: 0px;
  padding-right: 0px;
}

/*-------------------
       Compact
--------------------*/

.ui.compact.segment {
  display: table;
}

/* Compact Group */

.ui.compact.segments {
  display: inline-flex;
}

.ui.compact.segments .segment,
.ui.segments .compact.segment {
  display: block;
  flex: 0 1 auto;
}

/*-------------------
       Circular
--------------------*/

.ui.circular.segment {
  display: table-cell;
  padding: 2em;
  text-align: center;
  vertical-align: middle;
  border-radius: 500em;
}

/*-------------------
       Raised
--------------------*/

.ui.raised.segments,
.ui.raised.segment {
  box-shadow: 0px 2px 4px 0px rgba(34, 36, 38, 0.12), 0px 2px 10px 0px rgba(34, 36, 38, 0.15);
}

/*******************************
            Groups
*******************************/

/* Group */

.ui.segments {
  flex-direction: column;
  position: relative;
  margin: 1rem 0em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
}

.ui.segments:first-child {
  margin-top: 0em;
}

.ui.segments:last-child {
  margin-bottom: 0em;
}

/* Nested Segment */

.ui.segments > .segment {
  top: 0px;
  bottom: 0px;
  border-radius: 0px;
  margin: 0em;
  width: auto;
  box-shadow: none;
  border: none;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
}

.ui.segments:not(.horizontal) > .segment:first-child {
  border-top: none;
  margin-top: 0em;
  bottom: 0px;
  margin-bottom: 0em;
  top: 0px;
  border-radius: 0.28571429rem 0.28571429rem 0em 0em;
}

/* Bottom */

.ui.segments:not(.horizontal) > .segment:last-child {
  top: 0px;
  bottom: 0px;
  margin-top: 0em;
  margin-bottom: 0em;
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15), none;
  border-radius: 0em 0em 0.28571429rem 0.28571429rem;
}

/* Only */

.ui.segments:not(.horizontal) > .segment:only-child {
  border-radius: 0.28571429rem;
}

/* Nested Group */

.ui.segments > .ui.segments {
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  margin: 1rem 1rem;
}

.ui.segments > .segments:first-child {
  border-top: none;
}

.ui.segments > .segment + .segments:not(.horizontal) {
  margin-top: 0em;
}

/* Horizontal Group */

.ui.horizontal.segments {
  display: flex;
  flex-direction: row;
  background-color: transparent;
  border-radius: 0px;
  padding: 0em;
  background-color: #FFFFFF;
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15);
  margin: 1rem 0em;
  border-radius: 0.28571429rem;
  border: 1px solid rgba(34, 36, 38, 0.15);
}

/* Nested Horizontal Group */

.ui.segments > .horizontal.segments {
  margin: 0em;
  background-color: transparent;
  border-radius: 0px;
  border: none;
  box-shadow: none;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
}

/* Horizontal Segment */

.ui.horizontal.segments > .segment {
  flex: 1 1 auto;
  -ms-flex: 1 1 0px;
  /* Solves #2550 MS Flex */
  margin: 0em;
  min-width: 0px;
  background-color: transparent;
  border-radius: 0px;
  border: none;
  box-shadow: none;
  border-left: 1px solid rgba(34, 36, 38, 0.15);
}

/* Border Fixes */

.ui.segments > .horizontal.segments:first-child {
  border-top: none;
}

.ui.horizontal.segments > .segment:first-child {
  border-left: none;
}

/*******************************
            States
*******************************/

/*--------------
    Disabled
---------------*/

.ui.disabled.segment {
  opacity: 0.45;
  color: rgba(40, 40, 40, 0.3);
}

/*--------------
    Loading
---------------*/

.ui.loading.segment {
  position: relative;
  cursor: default;
  pointer-events: none;
  text-shadow: none !important;
  color: transparent !important;
  transition: all 0s linear;
}

.ui.loading.segment:before {
  position: absolute;
  content: '';
  top: 0%;
  left: 0%;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  border-radius: 0.28571429rem;
  z-index: 100;
}

.ui.loading.segment:after {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -1.5em 0em 0em -1.5em;
  width: 3em;
  height: 3em;
  -webkit-animation: segment-spin 0.6s linear;
  animation: segment-spin 0.6s linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-radius: 500rem;
  border-color: #767676 rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1);
  border-style: solid;
  border-width: 0.2em;
  box-shadow: 0px 0px 0px 1px transparent;
  visibility: visible;
  z-index: 101;
}

@-webkit-keyframes segment-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes segment-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/*******************************
           Variations
*******************************/

/*-------------------
       Basic
--------------------*/

.ui.basic.segment {
  background: none transparent;
  box-shadow: none;
  border: none;
  border-radius: 0px;
}

/*-------------------
       Clearing
--------------------*/

.ui.clearing.segment:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

/*-------------------
       Colors
--------------------*/

/* Red */

.ui.red.segment:not(.inverted) {
  border-top: 2px solid #DB2828 !important;
}

.ui.inverted.red.segment {
  background-color: #DB2828 !important;
  color: #FFFFFF !important;
}

/* Orange */

.ui.orange.segment:not(.inverted) {
  border-top: 2px solid #F2711C !important;
}

.ui.inverted.orange.segment {
  background-color: #F2711C !important;
  color: #FFFFFF !important;
}

/* Yellow */

.ui.yellow.segment:not(.inverted) {
  border-top: 2px solid #FBBD08 !important;
}

.ui.inverted.yellow.segment {
  background-color: #FBBD08 !important;
  color: #FFFFFF !important;
}

/* Olive */

.ui.olive.segment:not(.inverted) {
  border-top: 2px solid #B5CC18 !important;
}

.ui.inverted.olive.segment {
  background-color: #B5CC18 !important;
  color: #FFFFFF !important;
}

/* Green */

.ui.green.segment:not(.inverted) {
  border-top: 2px solid #21BA45 !important;
}

.ui.inverted.green.segment {
  background-color: #21BA45 !important;
  color: #FFFFFF !important;
}

/* Teal */

.ui.teal.segment:not(.inverted) {
  border-top: 2px solid #00B5AD !important;
}

.ui.inverted.teal.segment {
  background-color: #00B5AD !important;
  color: #FFFFFF !important;
}

/* Blue */

.ui.blue.segment:not(.inverted) {
  border-top: 2px solid #2185D0 !important;
}

.ui.inverted.blue.segment {
  background-color: #2185D0 !important;
  color: #FFFFFF !important;
}

/* Violet */

.ui.violet.segment:not(.inverted) {
  border-top: 2px solid #6435C9 !important;
}

.ui.inverted.violet.segment {
  background-color: #6435C9 !important;
  color: #FFFFFF !important;
}

/* Purple */

.ui.purple.segment:not(.inverted) {
  border-top: 2px solid #A333C8 !important;
}

.ui.inverted.purple.segment {
  background-color: #A333C8 !important;
  color: #FFFFFF !important;
}

/* Pink */

.ui.pink.segment:not(.inverted) {
  border-top: 2px solid #E03997 !important;
}

.ui.inverted.pink.segment {
  background-color: #E03997 !important;
  color: #FFFFFF !important;
}

/* Brown */

.ui.brown.segment:not(.inverted) {
  border-top: 2px solid #A5673F !important;
}

.ui.inverted.brown.segment {
  background-color: #A5673F !important;
  color: #FFFFFF !important;
}

/* Grey */

.ui.grey.segment:not(.inverted) {
  border-top: 2px solid #767676 !important;
}

.ui.inverted.grey.segment {
  background-color: #767676 !important;
  color: #FFFFFF !important;
}

/* Black */

.ui.black.segment:not(.inverted) {
  border-top: 2px solid #1B1C1D !important;
}

.ui.inverted.black.segment {
  background-color: #1B1C1D !important;
  color: #FFFFFF !important;
}

/*-------------------
       Aligned
--------------------*/

.ui[class*="left aligned"].segment {
  text-align: left;
}

.ui[class*="right aligned"].segment {
  text-align: right;
}

.ui[class*="center aligned"].segment {
  text-align: center;
}

/*-------------------
       Floated
--------------------*/

.ui.floated.segment,
.ui[class*="left floated"].segment {
  float: left;
  margin-right: 1em;
}

.ui[class*="right floated"].segment {
  float: right;
  margin-left: 1em;
}

/*-------------------
      Inverted
--------------------*/

.ui.inverted.segment {
  border: none;
  box-shadow: none;
}

.ui.inverted.segment,
.ui.primary.inverted.segment {
  background: #1B1C1D;
  color: rgba(255, 255, 255, 0.9);
}

/* Nested */

.ui.inverted.segment .segment {
  color: rgba(0, 0, 0, 0.87);
}

.ui.inverted.segment .inverted.segment {
  color: rgba(255, 255, 255, 0.9);
}

/* Attached */

.ui.inverted.attached.segment {
  border-color: #555555;
}

/*-------------------
     Emphasis
--------------------*/

/* Secondary */

.ui.secondary.segment {
  background: #F3F4F5;
  color: rgba(0, 0, 0, 0.6);
}

.ui.secondary.inverted.segment {
  background: #4c4f52 linear-gradient(rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 100%);
  color: rgba(255, 255, 255, 0.8);
}

/* Tertiary */

.ui.tertiary.segment {
  background: #DCDDDE;
  color: rgba(0, 0, 0, 0.6);
}

.ui.tertiary.inverted.segment {
  background: #717579 linear-gradient(rgba(255, 255, 255, 0.35) 0%, rgba(255, 255, 255, 0.35) 100%);
  color: rgba(255, 255, 255, 0.8);
}

/*-------------------
      Attached
--------------------*/

/* Middle */

.ui.attached.segment {
  top: 0px;
  bottom: 0px;
  border-radius: 0px;
  margin: 0em -1px;
  width: calc(100% - (-1px * 2));
  max-width: calc(100% - (-1px * 2));
  box-shadow: none;
  border: 1px solid #D4D4D5;
}

.ui.attached:not(.message) + .ui.attached.segment:not(.top) {
  border-top: none;
}

/* Top */

.ui[class*="top attached"].segment {
  bottom: 0px;
  margin-bottom: 0em;
  top: 0px;
  margin-top: 1rem;
  border-radius: 0.28571429rem 0.28571429rem 0em 0em;
}

.ui.segment[class*="top attached"]:first-child {
  margin-top: 0em;
}

/* Bottom */

.ui.segment[class*="bottom attached"] {
  bottom: 0px;
  margin-top: 0em;
  top: 0px;
  margin-bottom: 1rem;
  box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15), none;
  border-radius: 0em 0em 0.28571429rem 0.28571429rem;
}

.ui.segment[class*="bottom attached"]:last-child {
  margin-bottom: 0em;
}

/*-------------------
        Size
--------------------*/

.ui.mini.segments .segment,
.ui.mini.segment {
  font-size: 0.78571429rem;
}

.ui.tiny.segments .segment,
.ui.tiny.segment {
  font-size: 0.85714286rem;
}

.ui.small.segments .segment,
.ui.small.segment {
  font-size: 0.92857143rem;
}

.ui.segments .segment,
.ui.segment {
  font-size: 1rem;
}

.ui.large.segments .segment,
.ui.large.segment {
  font-size: 1.14285714rem;
}

.ui.big.segments .segment,
.ui.big.segment {
  font-size: 1.28571429rem;
}

.ui.huge.segments .segment,
.ui.huge.segment {
  font-size: 1.42857143rem;
}

.ui.massive.segments .segment,
.ui.massive.segment {
  font-size: 1.71428571rem;
}


/*******************************
            Dimmer
*******************************/

.dimmable:not(body) {
  position: relative;
}

.ui.dimmer {
  display: none;
  position: absolute;
  top: 0em !important;
  left: 0em !important;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  padding: 1em;
  background-color: rgba(0, 0, 0, 0.85);
  opacity: 0;
  line-height: 1;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  transition: background-color 0.5s linear;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  will-change: opacity;
  z-index: 1000;
}

/* Dimmer Content */

.ui.dimmer > .content {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  color: #FFFFFF;
}

/* Loose Coupling */

.ui.segment > .ui.dimmer {
  border-radius: inherit !important;
}

/* Scrollbars */

.ui.dimmer:not(.inverted)::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

.ui.dimmer:not(.inverted)::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.25);
}

.ui.dimmer:not(.inverted)::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.15);
}

.ui.dimmer:not(.inverted)::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.35);
}

/*******************************
            States
*******************************/

/* Animating */

.animating.dimmable:not(body),
.dimmed.dimmable:not(body) {
  overflow: hidden;
}

/* Animating / Active / Visible */

.dimmed.dimmable > .ui.animating.dimmer,
.dimmed.dimmable > .ui.visible.dimmer,
.ui.active.dimmer {
  display: flex;
  opacity: 1;
}

/* Disabled */

.ui.disabled.dimmer {
  width: 0 !important;
  height: 0 !important;
}

/*******************************
           Variations
*******************************/

/*--------------
    Legacy
---------------*/

/* Animating / Active / Visible */

.dimmed.dimmable > .ui.animating.legacy.dimmer,
.dimmed.dimmable > .ui.visible.legacy.dimmer,
.ui.active.legacy.dimmer {
  display: block;
}

/*--------------
    Alignment
---------------*/

.ui[class*="top aligned"].dimmer {
  justify-content: flex-start;
}

.ui[class*="bottom aligned"].dimmer {
  justify-content: flex-end;
}

/*--------------
      Page
---------------*/

.ui.page.dimmer {
  position: fixed;
  transform-style: '';
  perspective: 2000px;
  transform-origin: center center;
}

body.animating.in.dimmable,
body.dimmed.dimmable {
  overflow: hidden;
}

body.dimmable > .dimmer {
  position: fixed;
}

/*--------------
    Blurring
---------------*/

.blurring.dimmable > :not(.dimmer) {
  -webkit-filter: blur(0px) grayscale(0);
  filter: blur(0px) grayscale(0);
  transition: 800ms -webkit-filter ease;
  transition: 800ms filter ease;
  transition: 800ms filter ease, 800ms -webkit-filter ease;
}

.blurring.dimmed.dimmable > :not(.dimmer) {
  -webkit-filter: blur(5px) grayscale(0.7);
  filter: blur(5px) grayscale(0.7);
}

/* Dimmer Color */

.blurring.dimmable > .dimmer {
  background-color: rgba(0, 0, 0, 0.6);
}

.blurring.dimmable > .inverted.dimmer {
  background-color: rgba(255, 255, 255, 0.6);
}

/*--------------
    Aligned
---------------*/

.ui.dimmer > .top.aligned.content > * {
  vertical-align: top;
}

.ui.dimmer > .bottom.aligned.content > * {
  vertical-align: bottom;
}

/*--------------
    Inverted
---------------*/

.ui.inverted.dimmer {
  background-color: rgba(255, 255, 255, 0.85);
}

.ui.inverted.dimmer > .content > * {
  color: #FFFFFF;
}

/*--------------
     Simple
---------------*/

/* Displays without javascript */

.ui.simple.dimmer {
  display: block;
  overflow: hidden;
  opacity: 1;
  width: 0%;
  height: 0%;
  z-index: -100;
  background-color: rgba(0, 0, 0, 0);
}

.dimmed.dimmable > .ui.simple.dimmer {
  overflow: visible;
  opacity: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 1;
}

.ui.simple.inverted.dimmer {
  background-color: rgba(255, 255, 255, 0);
}

.dimmed.dimmable > .ui.simple.inverted.dimmer {
  background-color: rgba(255, 255, 255, 0.85);
}