@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700&display=swap");

$color-white: #fff;
$color-black: #000;
$color-red: #ae2939;
$color-green: #21ba45;
$color-blue: #024c8b;

$color-gray: #e0e1e2;
$color-gray-f2: #f2f2f2;
$color-gray-d9: #d9d9d9;
$color-gray-f6: #fff6f6;

#documents-upload-root {
  margin: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
}

#documents-upload-root > * {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
}

.documents-upload-root {
  h1 {
    color: $color-blue;
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 600;
  }

  .documents-upload-container {
    background: $color-gray-f2;
    border: 1px solid $color-gray-d9;
    padding: 40px 25%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: 500px;

    @media screen and (max-width: 450px) {
      padding: 40px 10%;
    }

    > .button {
      max-width: 250px;
      margin-top: 25px;
      align-self: center;
    }

    &.expired {
      justify-content: center;
      align-items: center;

      .segment {
        text-align: center;
      }
    }
  }

  .sk-error-msg {
    padding: 24px;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 17px;
    background-color: $color-gray-f6;
    color: $color-red;
    border: 1px solid $color-red;
    border-radius: 4px;
  }
}

.sk-file-upload {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  margin-top: 8px;

  > div {
    display: flex;
    gap: 20px;

    @media screen and (max-width: 450px) {
      flex-direction: column;
    }
  }
}

.field > label {
  position: relative;
  color: #024c8b;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 8px;
}

.sk-label-text--required::after {
  margin: -0.2em 0 0 0.2em;
  content: '*';
  color: #db2828;
  display: inline-block;
  vertical-align: top;
}

.field.toggle {
  display: flex;
  flex-direction: column;
}

.other-documents {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  &__item {
    width: 100%;
    display: flex;
    align-items: end;
    gap: 20px;
    justify-content: space-between;

    @media screen and (max-width: 450px) {
      flex-direction: column;
      align-items: baseline;
    }
  }
}